import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CopyRight from "../promoter/CopyRight";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { phoneNumberValidate } from "../common/validations/formValidation";
import { connect } from "react-redux";
import { getSession, sendOTP } from "../store/actions/authActions";
import ActivityIndigator from "../shared/ActivityIndigator";
import TextError from "../shared/TextError";

const Login = ({ type, sendOTPHandler, submitting, isLogin }) => {
  let userInfo = getSession();
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo.Authorization) {
      if (userInfo.type === "admin") navigate("/admin/dashboard");
      else if (userInfo.type === "promoter") navigate("/promoter/dashboard");
    }
  }, [navigate, userInfo.Authorization]);

  const initialValues = {
    phoneNumber: "",
    type,
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    sendOTPHandler(
      {
        ...values,
      },
      resetForm,
      onAction
    );
  };

  const onAction = () => {
    navigate("/admin/verify-otp");
  };
  return (
    <div className="promoter_page_spacing">
      <div className="align_center_div login">
        <div className="ald_inner">
          <div className="adminlogin">
            <div className="logo">
              <img src="../assets/img/logo_businessbaab2.svg" alt="" />
            </div>
            <div className="dvg40"></div>
            <h6 className="dr18">Welcome to BusinessBaab</h6>
            <div className="vg12"></div>
            <div className="loginwrapper">
              <h5 className="dm18">
                {type === "admin" ? "Admin" : "Promoter"} Login
              </h5>
              <div className="vg22"></div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={phoneNumberValidate}
              >
                <Form>
                  {" "}
                  <div className="form_field loginnumber">
                    <label htmlFor="">Mobile Number*</label>
                    {/* <Field name="phoneNumber">
                      {({ field, form }) => (
                        <PhoneInput
                          {...field}
                          international
                          defaultCountry="IN"
                          withCountryCallingCode={true}
                          id="phoneNumber"
                          name="phoneNumber"
                          onBlur={field.onBlur}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                        />
                      )}
                    </Field>{" "} */}
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Enter phoneNumber"
                    />
                    <ErrorMessage name="phoneNumber" component={TextError} />
                  </div>{" "}
                  <div className="vg22"></div>
                  <button
                    type="submit"
                    className="theme_btn"
                    disabled={submitting}
                  >
                    {" "}
                    {submitting ? <ActivityIndigator /> : "Submit "}
                  </button>
                </Form>
              </Formik>
            </div>
            <div className="vg12"></div>   
            {/* {type === "admin" ? "Admin" : "Promoter"}   
            <Link to="/promotercontactus" className="grad_text gt_border">
              Contact Us
            </Link>  */}
            <Link to="/promotercontactus" className="grad_text gt_border">
              Contact Us
            </Link>
            <div className="vg22"></div>
            <h6 className="r14">
              Don't have a promoter account?     </h6>
              <div className="vg6"></div>
              <Link to="https://businessbaab.com/referrals" className="grad_text gt_border">
             Create First
            </Link>
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
    tempInfo: auth.tempInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendOTPHandler: (data, resetForm, onAction) =>
      dispatch(sendOTP(data, resetForm, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
