const initialState = {
  error: "",
  loading: false,
  user: {},
  lists: [],
  totalDocuments: 0,
  currentPage: 1,
  lastPage: 1,
  backendAssetUrl: "",
  details: { paymentHistories: [] },
  // promoting users
  // user: {
  //   lists: [],
  //   totalDocuments: 0,
  //   currentPage: 1,
  //   lastPage: 1,
  //   backendAssetUrl: "",
  // },
};
const promoterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PROMOTERS_LIST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_PROMOTERS_LIST":
      return {
        ...state,
        loading: false,
        lists: action.payload,
        ...action.extra,
      };
    case "SET_PROMOTERS_DETAILS":
      return {
        ...state,
        loading: false,
        details: action.payload,
        ...action.extra,
      };
    case "RESET_PROMOTERS_DETAILS":
      return {
        ...state,
        loading: false,
        details: { paymentHistories: [] },
        service: {
          totalDocuments: 0,
          currentPage: 0,
          lastPage: 0,
          data: [],
        },
      };

    case "SET_PROMOTERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.err?.message,
      };

    default:
      return state;
  }
};

export default promoterReducer;
