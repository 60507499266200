const initialState = {
  error: "",
  loading: false,
  user: {},
  lists: [],
  totalDocuments: 0,
  currentPage: 1,
  lastPage: 1,
  backendAssetUrl: "",
  details: {},
};
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PAYMENT_LIST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_PAYMENT_LIST":
      return {
        ...state,
        loading: false,
        lists: action.payload,
        ...action.extra,
      };

    case "SET_PAYMENT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.err?.message,
      };

    default:
      return state;
  }
};

export default paymentReducer;
