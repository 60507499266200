import * as Yup from "yup";

export const phoneNumberValidate = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
});

export const contactSchema = Yup.object().shape({
  // name: Yup.string().required("please enter your name").label("name"),
  // phoneNumber: Yup.string()
  //   .required("Please enter valid phone number ")
  //   .label("phone number")
  //   .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
  //   .min(10, "Phone number must be at least 10 digits.")
  //   .max(13, "Phone number must not be more than 13 digits."),

  message: Yup.string().required("please enter your query"),
});
export const promoterSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
  countryId: Yup.string().required("Please select a country").label("country"),
  stateId: Yup.string().required("Please select a state").label("state"),
  cityId: Yup.string().required("Please select a city").label("city"),
  fullAddress: Yup.string().required("please enter your full address"),
  paymentMethod: Yup.string().required("please select a payment method"),
  upiIdOrNumber: Yup.string().required("please enter your upi id or number"),
});
