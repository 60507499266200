import React from 'react'
import { Link } from "react-router-dom";

const CopyRight = () => {
  return (
    <div className='pcopyright'>
      <div className="mii">
        <img src="./assets/img/makeinindia.png" alt="" />
        <img src="./assets/img/startupindia.svg" alt="" />
      </div>
      <div className="reserved">    
      <span>All rights are reserved.</span>
      <span>Powered by <Link to="">Absne Private Limited</Link></span>
      </div>
    </div>
  )
}

export default CopyRight
