import { APIs } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
import { getUserInfo } from "./userActions";
export const sendContactDetails =
  (payload, onAction = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(APIs.SEND_CONTACT_DETAILS, {
        ...payload,
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const sendHelpSupportDetails =
  (payload, onAction = undefined, setShowMsg = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(APIs.SEND_SUPPORT_DETAILS, {
        ...payload,
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        // showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
        if (setShowMsg !== undefined) {
          setShowMsg(true);
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const updatePromoterDetails =
  (payload, resetForm = undefined, onAction = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      let fd = new FormData();
      for (let key in payload) {
        fd.append(key, payload[key]);
      }
      const { data } = await axios({
        method: "post",
        url: APIs.UPDATE_PROMOTER_PROFILE,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (resetForm !== undefined) {
          resetForm();
        }
        if (onAction !== undefined) {
          onAction();
        }
        dispatch(getUserInfo());
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const deleteContact = (_id, onAction) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${APIs.DELET_CONTACT_DETAILS}/${_id}`);

    if (data.status) {
      dispatch({ type: "FILTER_CONTACT_LISTS", payload: _id });
      showSuccess(data.msg);
      if (onAction !== undefined) onAction();
    } else if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};

// admin functionality

export const getContactLists = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_CONTACT_LISTS",
    });
    const { data } = await axios.post(APIs.GET_CONTACT_LISTS, { ...payload });

    if (data.status) {
      dispatch({
        type: "SET_CONTACT_LISTS",
        payload: data.data || [],
        extra: {
          totalDocuments: data?.totalDocuments || 0,
          currentPage: data?.currentPage || 0,
          lastPage: data?.lastPage || 0,
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SAVE_CONTACT_ERROR",
      err,
    });
    showThrowError(err);
  }
};
export const getContactQueryLists = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_CONTACT_LISTS",
    });
    const { data } = await axios.post(APIs.GET_CONTACT_QUERIES, { ...payload });

    if (data.status) {
      dispatch({
        type: "SET_CONTACT_QUERIES",
        payload: data.data || [],
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SAVE_CONTACT_ERROR",
      err,
    });
    showThrowError(err);
  }
};

export const changeContactStatus = async (payload) => {
  try {
    const { data } = await axios.post(`${APIs.UPDATE_CONTACT_STATUS}`, {
      ...payload,
    });
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const updateLocalStatus = (payload) => async (dispatch) => {
  dispatch({
    type: "UPDATE_CONTACT_STATUS",
    payload,
  });
};
