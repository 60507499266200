import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";

import CopyRight from "../promoter/CopyRight";
import { resendOTP, verifyOTP } from "../store/actions/authActions";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import ActivityIndigator from "../shared/ActivityIndigator";

const Otp = ({
  tempInfo,
  type,
  resendOTPhandler,
  verifyOTPHandler,
  submitting,
}) => {
  const [OTP, setOTP] = useState("");
  const [hasError, setHasError] = useState(false);
  const [counter, setCounter] = React.useState(25);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const navigate = useNavigate();
  const handleChange = (otp) => {
    // if (otp.length === 4) {
    //   if (tempInfo.OTP !== Number(otp)) {
    //     setHasError(true);
    //   }
    // } else {
    setHasError(false);
    // }
    setOTP(otp);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyOTPHandler(OTP, navigate, setHasError);
      }
    } else {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyOTPHandler(OTP, navigate, setHasError);
      }
    }
  };

  const displayTimer = (time_seconds) => {
    let minutes = Math.floor((time_seconds % 3600) / 60);
    let seconds = time_seconds % 60;
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

    return `${minutes}:${seconds}`;
  };

  const showTimer = useMemo(() => displayTimer(counter), [counter]);
  return (
    <div className="promoter_page_spacing">
      <div className="align_center_div otp_pg">
        <div className="ald_inner">
          <div className="adminlogin">
            <div className="logo">
              <img src="./assets/img/logo_businessbaab2.svg" alt="" />
            </div>
            <div className="dvg40"></div>
            <h6 className="dr18">Welcome to BusinessBaab</h6>
            <div className="vg12"></div>
            <div className="loginwrapper">
              <h5 className="dm18">
                {type === "admin" ? "Admin" : "Promoter"} Login
              </h5>
              <div className="vg22"></div> <span>{tempInfo?.OTP}</span>
              <Form onSubmit={handleVerify}>
                <div className="form_field otp">
                  <label htmlFor="">Enter 4 digit OTP</label>
                  <OTPInput
                    className={`otp_input ${hasError ? "b-red" : ""}`}
                    inputStyle="inputStyle"
                    value={OTP}
                    onChange={handleChange}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    isInputNum={true}
                    hasErrored={hasError}
                    placeholder="0000"
                    errorStyle="error"
                  />
                  {hasError && (
                    <span className="err_red ">enter valid otp</span>
                  )}
                </div>{" "}
                {showTimer === "00:00" ? null : (
                  <p>resend OTP in {showTimer}</p>
                )}
                {counter === 0 && (
                  <p className="resend_otp">
                    didn't recieve an OTP?
                    <span
                      className="cursor pl-2"
                      onClick={() => {
                        resendOTPhandler(setCounter);
                      }}
                    >
                      resend OTP
                    </span>
                  </p>
                )}
                <div className="vg22"></div>
                <button
                  // to="/select-account-type"
                  disabled={submitting}
                  className="theme_btn"
                >
                  {submitting ? <ActivityIndigator /> : "Submit"}
                </button>
              </Form>
            </div>
            <div className="vg12"></div>
            <Link className="grad_text gt_border" to="/promotercontactus">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
    tempInfo: auth.tempInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyOTPHandler: (data, navigate, setHasError) =>
      dispatch(verifyOTP(data, navigate, setHasError)),
    resendOTPhandler: (setCounter) => dispatch(resendOTP(setCounter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
