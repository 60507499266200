import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/authActions";
import { LogoutConfirmation } from "../shared/Model/LogoutConfirmation";

const PromoterNavbar = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout(navigate));
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary superadmin_navbar">
        <Container>
          <Link className="brand_logo">
            <img src="/assets/img/logo_businessbaab2.svg" alt="" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="sa_menus">
              <Link
                to="/promoter/dashboard"
                className={`sam_single ${location.pathname === "/promoter/dashboard" ? "sam_active" : ""
                  }`}
              >
                Dashboard
              </Link>
              <Link
                to="/promoter/profile"
                className={`sam_single ${location.pathname === "/promoter/profile" ? "sam_active" : ""
                  }`}
              >
                My Profile
              </Link>
              <Link
                to="/promoter/vendor"
                className={`sam_single ${location.pathname === "/promoter/vendor" ? "sam_active" : ""
                  }`}
              >
                Vendor
              </Link>
              <Link
                to="/promoter/faq"
                className={`sam_single ${location.pathname === "/promoter/faq" ? "sam_active" : ""
                  }`}
              >
                FAQ
              </Link>
              <Link
                to="/promoter/contactus"
                className={`sam_single ${location.pathname === "/promoter/contactus" ? "sam_active" : ""
                  }`}
              >
                Contact Us
              </Link>
              <Link
                onClick={() => setShowModal(true)}
                className={`sam_single ${location.pathname === "/logout" ? "sam_active" : ""
                  }`}
              >
                Logout
              </Link>

            </div>
          </Navbar.Collapse>
        </Container>
        <LogoutConfirmation
          show={showModal}
          onHide={() => setShowModal(false)}
          actionHandler={onLogout}
        />
      </Navbar>





    </>


  );
};

export default PromoterNavbar;
