import { combineReducers } from "redux";
import formReducer from "./formReducer";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import userReducer, { dashboardReducer } from "./userReducer";
import accountReducer from "./accountReducer";
import { commonReducer, profileReducer } from "./publiceReducer";

import promoterReducer from "./promoterReducer";
import paymentReducer from "./paymentReducer";
import contactReducer from "./contactReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  auth: authReducer,
  category: categoryReducer,
  user: userReducer,
  common: commonReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  promoter: promoterReducer,
  payment: paymentReducer,
  contact: contactReducer,
});
export default rootReducer;
