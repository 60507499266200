// development
// const BASE_URL = "http://127.0.0.1:5000/api/v1";
// const BACKEND_ASSETS = "http://127.0.0.1:4000";
// production
const BASE_URL = "https://api.businessbaab.com/api/v1";
const BACKEND_ASSETS = "https://api.businessbaab.com";

const AUTHORIZATION = "Authorization";
const COOKIE_EXPIRED = 60 * 60 * 24 * 365;
const APIs = {
  // auth
  SEND_OTP: "/admin/auth/sendOtp",
  VERIFY_OTP: "/admin/auth/validateOtp",

  // superadmin
  GET_USER_LISTS: "/admin/users/lists",
  GET_SINGLE_USER_DETAILS: "/admin/users/details",
  GET_PROMOTER_LISTS: "/admin/promoters/lists",
  GET_SINGLE_PROMOTER_DETAILS: "/admin/promoters/details",
  DELETE_PROMOTER: "/admin/promoters/delete",
  CHANGE_PROMOTER_STATUS: "/admin/promoters/changePromoterStatus",

  DELETE_USER: "/admin/users/delete",
  CHANGE_USER_STATUS: "/admin/users/changeUserStatus",

  // promoter
  GET_PROMTER_DETAILS: "/promoter/details",
  UPDATE_PROMOTER_PROFILE: "/promoter/update",
  GET_PAYMENT_LISTS: "/admin/payment/lists",
  TRANSFER_AMOUNT: "/admin/payment/transfer",

  // analtyics
  GET_ANALYTICS_COUNTS: "/admin/dashboard",

  // common
  GET_ALL_COUNTRY: "/common/countries",
  GET_ALL_STATE_BY_COUNTRY: "/common/states",
  GET_ALL_CITY_BY_STATE: "/common/cities",

  //category
  GET_ALL_CATEGORIES: "/category",

  //contact & support
  SEND_CONTACT_DETAILS: "/public/contact/send",
  SEND_SUPPORT_DETAILS: "/public/support/send",

  GET_CONTACT_LISTS: "/admin/contacts/lists",
  GET_CONTACT_QUERIES: "/admin/contacts/queries",
  UPDATE_CONTACT_STATUS: "/admin/contacts/updateStatus",
};

const NAVIGATION_ROUTE = {
  SELECT_ACCOUNT_TYPE: "/account/select-account-type",
  USER_ACCOUNT: "/account",
  USER_DASHBOARD: "/dashboard",
  PERSONAL_INFO: "/account/personal-info",
  BUSINESS_INFO: "/account/business-info",
  SERVICE_INFO: "/account/service-info",
  PAYMENT_INFO: "/account/payment-info",
  CHOOSE_PLAN: "/account/choose-plan",
  CONGRADULATION: "/account/congratulation",
};

export {
  BASE_URL,
  BACKEND_ASSETS,
  AUTHORIZATION,
  COOKIE_EXPIRED,
  APIs,
  NAVIGATION_ROUTE,
};
