import React from "react";
import { Modal } from "react-bootstrap";
import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";
const CardPreviewModel = ({ show, onHide, URL }) => {
  const handleDownload = () => {
    const downloadCard = document.getElementById("dowanlod_card");

    // Use html2canvas to convert the div content to an image
    html2canvas(downloadCard, { width: 432 }).then((canvas) => {
      // Convert canvas to data URL
      const dataURL = canvas.toDataURL("image/png");

      // Create a temporary link element
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = "BusinessBaab-QR-Code.png";

      // Append the link to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Remove the link from the document
      document.body.removeChild(a);
    });
  };
  return (
    <Modal show={show} onHide={onHide} className="my_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Download My QR Code</Modal.Title>
        <span
          class="material-symbols-outlined modal_close red"
          onClick={onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body style={{
        overflow:"auto"
      }}>
        <div className="dowanlod_wrapper" id="dowanlod_card">
          <div className="our_logo">
            <img src="../assets/img/logo_businessbaab2.svg" alt="" />
          </div>
          <div className="vg8"></div>
          <div className="qr_code">
           <div className="favicon">
            <img src="/assets/img/fevicon_browser.png" alt="" />
           </div>
            <QRCodeSVG
              value={URL}
              size={"100%"}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              style={{ width: "100%", height: "100%" }}
              // imageSettings={{
              //   src: "https://static.zpao.com/favicon.png",
              //   x: undefined,
              //   y: undefined,
              //   height: 24,
              //   width: 24,
              //   excavate: true,
              // }}
            />
          </div>
          <div className="vg12"></div>
          <h6
            className="m14"
            style={{
              lineHeight: "20px",
              marginBottom: "0px",
              maxWidth: "245px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            स्केन करें और 10 मिनट में अपनी खुद की वेबसाइट बनाएँ मात्र ₹75 माह
            में
          </h6>
          <div
            className="ab_divider"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
            }}
          ></div>
          <h6
            className="m14"
            style={{
              lineHeight: "20px",
              marginBottom: "0px",
              maxWidth: "245px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Scan and Build Your Own Website in 10 Minutes Just ₹75 Months
          </h6>
          <div className="vg12"></div>
          <div className="mii">
            <img src="/assets/img/lion.png" alt="" />
            <img src="/assets/img/startup.svg" alt="" />
          </div>
        </div>
        <div className="vg12"></div>
        <div
          className="row row_gap"
          style={{
            width: "432px",
            maxWidth: "100%",
            margin: "auto",
          }}
        >
          <div className="col-sm-8">
            <div className="modal_btn pointer" onClick={handleDownload}>
              Download and Print
            </div>
          </div>
          <div className="col-sm-4">
            <div className="modal_btn pointer">Share</div>
          </div>
        </div>
        <div className="vg12"></div>
      </Modal.Body>
    </Modal>
  );
};

export default CardPreviewModel;
