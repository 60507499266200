import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import Switch from "react-switch"; // npm install react-switch
import Modal from "react-bootstrap/Modal";

// import navbar
import SuperAdminNavbar from "./SuperAdminNavbar";
import { connect } from "react-redux";
import {
  changePromoterStatus,
  deletePromoter,
  getPromoterDetails,
  getUsersList,
} from "../store/actions/userActions";
import {
  getCities,
  getCountries,
  getStates,
} from "../store/actions/commonActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import SearchFilter from "../shared/SearchFilter";
import {
  DATE_FILTER_OPTIONS,
  PLAN_TYPE_FILTER_OPTIONS_EXPAND,
} from "../common/constants";
import {
  dateToFormat,
  getPageNo,
  isDateExpired,
} from "../common/helpers/_helper";
import { Paginate } from "../shared/Pagination/Paginate";
import AlertPopup from "../shared/Model/AlertPopup";
import TransferAmount from "./TransferAmount";

const PromoterSingle = ({
  loading,
  lists,
  totalDocuments,
  getUsersList,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  currentPage,
  lastPage,
  details,
  getPromoterDetails,
  resetDetail,
  backendAssetUrl,
}) => {
  const { promoterId } = useParams();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [popupAction, setPopupAction] = useState(false);
  const [showSAAlert, setShowSAAlert] = useState(false);
  const [saSwitch, setSASwitch] = useState(true);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showPaymentTransfer, setShowPaymentTransfer] = useState(false);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    limit: 50,
    sortType: -1,
    search: "",
    countryId: "",
    stateId: "",
    cityId: "",
    planType: "",
    status: "all", //paid or remaining<
    filter: "", //lifetime,1 month ,3 months,6 months,1 year
    startDate: "", //on custom
    endDate: "",
  });

  useEffect(() => {
    getUsersList({ pageNumber: 1, limit: 50, promoterId });
    getCountries();
  }, [getUsersList, getCountries, promoterId]);
  useEffect(() => {
    getPromoterDetails(promoterId);
    return () => resetDetail();
  }, [promoterId, getPromoterDetails, resetDetail]);

  useEffect(() => {
    if (details) setSASwitch(details?.status);
  }, [details]);
  const togglePayment = () => setShowPaymentHistory(!showPaymentHistory);

  const toggleSwitch = () => {
    setShowSAAlert(!showSAAlert);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if (name === "countryId") {
      getStates(value);
    } else if (name === "stateId") {
      getCities(value);
    }
  };
  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // if (search || search !== null) {
      getUsersList({
        ...filters,
        pageNumber: 1,
        limit: Number(filters.limit),
        promoterId,
      });
      // }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filters, getUsersList, promoterId]);

  const handlePageClick = ({ selected: page }) => {
    getUsersList({
      ...filters,
      limit: Number(filters.limit),
      pageNumber: page + 1,
      promoterId,
    });
  };

  const getStatusClass = (list) => {
    if (list?.isGrantUser) {
      return "grant";
    } else if (
      isDateExpired(list?.subscription.expiresAt) &&
      ["yearly", "monthly"].includes(list?.subscription.planType)
    ) {
      return "stopped";
    } else if (
      list?.subscription?.planType === "yearly" ||
      list?.subscription?.planType === "monthly"
    ) {
      return "paid";
    } else {
      return "unpaid";
    }
  };
  const getStatusTitle = (list) => {
    if (list?.isGrantUser) {
      return "grant";
    } else if (
      isDateExpired(list?.subscription.expiresAt) &&
      ["yearly", "monthly"].includes(list?.subscription.planType)
    ) {
      return "stopped";
    } else if (list?.subscription?.planType === "yearly") {
      return "paid (Y)";
    } else if (list?.subscription?.planType === "monthly") {
      return "paid (M)";
    } else {
      return "unpaid";
    }
  };
  const handleChangeSwitch = () => {
    setPopupAction(true);
    changePromoterStatus({
      promoterId: promoterId,
      type: "status",
      status: !saSwitch,
    })
      .then(() => {
        setShowSAAlert(false);
        setSASwitch(!saSwitch);
      })
      .finally(() => setPopupAction(false));
  };
  const handleDelete = () => {
    setPopupAction(true);
    deletePromoter(promoterId)
      .then(() => {
        setShowDeleteAlert(false);
        navigate("/admin/promoter");
      })
      .finally(() => setPopupAction(false));
  };
  const handleDownload = (list) => {
    const link = document.createElement("a");
    link.href = `${backendAssetUrl}${list.attachment}`;
    link.download = "screenshot";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="vendor_single promoter_single set_filters">
      {" "}
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link to={"/admin/promoter"} className="back_div">
              <span
                className="material-symbols-outlined"
                onClick={handleGoBack}
              >
                arrow_back
              </span>
              <h6 className="r14">Promoter Information</h6>
            </Link>
          </div>
          <div className="right">
            <div className="diff_action">
              <div className="action_single">
                <div
                  className={`my_switch switch_with_inline_label ${
                    saSwitch ? "on" : "off"
                  }`}
                >
                  <div className="switch">
                    <h6 className={`r12 ${saSwitch ? "" : "blue"}`}>Stop</h6>
                    <Switch checked={saSwitch} onChange={toggleSwitch} />
                    <h6 className={`r12 ${saSwitch ? "blue" : ""}`}>Active</h6>
                  </div>
                </div>
              </div>
              <span class="material-symbols-outlined action_single red pointer"  onClick={() => setShowDeleteAlert(true)}>delete_forever</span>
            
            </div>
          </div>
        </section>
        <div className="vg22"></div>
        <section className="vendor_personal_info">
          <div className="left">
            <div className="v_img">
              {details?.profilePic && (
                <img
                  src={`${backendAssetUrl}/${details?.profilePic}`}
                  alt={details?.name}
                />
              )}
            </div>
            <div className="vg8"></div>
            <h5 className="m12 text-center">{details?.profession || ""}</h5>
          </div>
          <div className="right">
            <h4 className="m18">{details?.name || ""}</h4>
            <div className="vg6"></div>
            <h6 className="r14 d-flex align-items-center">
              <span class="material-symbols-outlined icon14">location_on</span>{" "}
              {details?.address?.fullAddress},{details?.address?.cityName},
              {details?.address?.stateName},{details?.address?.countryName}
            </h6>
            <div className="vg3"></div>
            <h6 className="r14 d-flex align-items-center">
              <span className="d-flex align-items-center">
                <img src="/assets/img/whatsapp.png" alt="" className="img14" />
                {details?.phoneNumber || ""}, &nbsp; &nbsp;
              </span>
            </h6>

            <div className="vg8"></div>
            <div className="short_table">
              <div className="left">
                <img src="/assets/img/phonepe.png" alt="" />
                {details?.recievePaymentDetails?.paymentMethod}{" "}
                {details?.recievePaymentDetails?.upiIdOrNumber} |{" "}
                {details?.recievePaymentDetails?.numberOfDays === 1 && (
                  <span>Daily Basis</span>
                )}
                {details?.recievePaymentDetails?.numberOfDays === 15 && (
                  <span> 15 Days</span>
                )}
                {details?.recievePaymentDetails?.numberOfDays === 7 && (
                  <span>7 Days</span>
                )}
              </div>
              <div className="right">
                <div className="rsingle m14 blue d-flex">
                  Total Earned :{details?.totalEarning || "0"}
                  <div
                    className="view pointer"
                    style={{
                      marginLeft: "4px",
                    }}
                  >
                    <img src="/assets/img/eye.png" alt="" />
                  </div>
                </div>
                <div className="rsingle m14 blue">
                  Success Rate :{details?.successRate || "0%"}
                </div>
                <div className="rsingle m14 blue">
                  Grants : {details?.totalGrantUsers || "0"}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="vg22"></div>
        <section className="payment_info">
          <div className="left">
            <h6 className="r14">
              Remaining :{" "}
              <span className="m18">{details?.totalRemainingAmount || 0}</span>
            </h6>
          </div>
          <div className="right">
            <div
              className="theme_btn short_btn view_btn"
              onClick={() => setShowPaymentTransfer(true)}
            >
              Transfer Payment
            </div>
            <div
              className="theme_btn short_btn view_btn btn_border"
              onClick={togglePayment}
            >
              Payment History
            </div>
          </div>
        </section>
        <div className="vg22"></div>
        <section className="diff_filters">
          <div className="row row_gap">
            <div className="col-lg-10">
              <div className="left">
                <div className="filters_group">
                  <SearchFilter
                    setSearch={handleSearch}
                    search={filters.search}
                  />
                  <div className="df_single">
                    <div className="form_field">
                      <select
                        name="countryId"
                        id="countryId"
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option
                            value={country.countryId}
                            key={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single">
                    <div className="form_field">
                      <select
                        name="stateId"
                        id="stateId"
                        onChange={handleChange}
                      >
                        <option value="">Select state</option>
                        {states.map((state) => (
                          <option value={state.stateId} key={state.stateId}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>{" "}
                  <div className="df_single ">
                    <div className="form_field">
                      <select name="cityId" id="cityId" onChange={handleChange}>
                        <option value="">Select city</option>
                        {cities.map((city) => (
                          <option value={city.cityId} key={city.cityId}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single">
                    <div className="form_field">
                      <select
                        name="planType"
                        id="planType"
                        onChange={handleChange}
                      >
                        {PLAN_TYPE_FILTER_OPTIONS_EXPAND.map((f, i) => (
                          <option key={`plan-type-${i}`} value={f}>
                            {f}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end d-block">
              <div className="right">
                <div className="form_field">
                  <select name="filter" id="filter" onChange={handleChange}>
                    {DATE_FILTER_OPTIONS.map((f, i) => (
                      <option key={`date-${i}`} value={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="vg12"></div>
        <div className="promoter_table table-responsive">
          <table class="col-md-12 table-bordered table-striped table-condensed cf">
            <thead class="cf">
              <tr>
                <th>S. N.</th>
                <th>Name</th>
                <th>Business Name</th>
                <th class="numeric">Mobile</th>
                <th>Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {lists.length > 0 ? (
                lists.map((list, i) => (
                  <tr key={list._id}>
                    <td data-title="S.N.">{i + 1}.</td>
                    <td data-title="Name">
                      {" "}
                      {list?.personalInfo?.name || "Unknown"}
                    </td>
                    <td data-title="Business Name">
                      {" "}
                      {list?.businessInfo?.title || "Unknown"}
                    </td>
                    <td data-title="Mobile" class="numeric">
                      {list?.phoneNumber || ""}
                    </td>
                    <td data-title="Address">
                      {list?.businessInfo?.address?.cityName || "-"}{" "}
                      <p className="td_short_info">
                        {" "}
                        {list?.businessInfo?.address?.stateName || "-"}{" "}
                      </p>
                    </td>
                    <td
                      data-title="Status"
                      className={`status ${getStatusClass(list)}`}
                    >
                      <span>{getStatusTitle(list)}</span>
                    </td>{" "}
                    <td data-title="Action">
                      <Link
                        to={`/admin/vendor/${list._id}`}
                        className="grad_text gt_border"
                      >
                        view
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="vg12"></div>
        {lists && lists.length > 0 && (
          <section className="table_pagination record_view">
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-between p-4">
                  <Paginate
                    totalCounts={totalDocuments}
                    perPage={filters.limit}
                    currentPage={currentPage}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div className="form_field">
                  <select name="limit" id="limit" onChange={handleChange}>
                    {getPageNo(totalDocuments, 50)}
                  </select>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      {showDeleteAlert && (
        <AlertPopup
          onHide={() => setShowDeleteAlert(false)}
          show={showDeleteAlert}
          actionHandler={handleDelete}
          message={"Are you sure you want to delete this promoter?"}
          action={popupAction}
          buttonText={"Delete"}
        />
      )}
      {showSAAlert && (
        <AlertPopup
          onHide={() => setShowSAAlert(false)}
          show={showSAAlert}
          actionHandler={handleChangeSwitch}
          message={`Are you sure you want to ${
            saSwitch ? "stop" : "active"
          } this promoter?`}
          action={popupAction}
          buttonText={saSwitch ? "Stop Promoter" : "Active Promoter"}
        />
      )}
      {showPaymentTransfer && (
        <TransferAmount
          show={showPaymentTransfer}
          onHide={() => setShowPaymentTransfer(false)}
          details={details}
        />
      )}
      {/* Modal for Payment history */}
      <Modal
        show={showPaymentHistory}
        onHide={togglePayment}
        className="my_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">Payment History</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={togglePayment}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <div className="payment_history">
            {details?.paymentHistories.length > 0 ? (
              details?.paymentHistories.map((list, i) => (
                <div className="ph_single" onClick={() => handleDownload(list)}>
                  <span className="r14">{dateToFormat(list.date)}</span>
                  <span className="semibold">{list.amount || "0"}</span>
                  <div
                    className="d-flex align-item-center"
                    onClick={() => handleDownload(list)}
                  >
                    <span
                      class="material-symbols-outlined grad_text"
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      download
                    </span>
                    <span className="grad_text">Screenshot</span>
                  </div>
                </div>
              ))
            ) : (
              <span>No history found</span>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal for Payment history */}
    </div>
  );
};
const mapStateToProps = ({
  user: {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
  },
  category: { lists: categories },
  common,
  promoter: { details },
}) => {
  return {
    loading,
    lists,
    totalDocuments,
    currentPage,
    lastPage,
    backendAssetUrl,
    ...common,
    details,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPromoterDetails: (_id) => dispatch(getPromoterDetails(_id)),
    resetDetail: () => {
      dispatch({ type: "RESET_PROMOTERS_DETAILS" });
    },
    getUsersList: (payload) => dispatch(getUsersList(payload)),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoterSingle);
