import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from "formik";
import EditProfileInfo from "./models/EditProfileInfo";
import {
  getCities,
  getCountries,
  getStates,
} from "../store/actions/commonActions";
import { connect } from "react-redux";
import { updatePromoterDetails } from "../store/actions/contactActions";

const PromoterProfile = ({
  userDetails,
  submitting,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  updatePromoterDetails,
}) => {
  const [image, setImage] = useState(null);
  const [showEditProfile, setShowEditProfile] = useState(false);
  useEffect(() => {
    getCountries();
    if (userDetails !== undefined) {
      if (userDetails?.address?.countryId) {
        getStates(userDetails?.address?.countryId);
      } else {
        getStates(101);
      }
      if (userDetails?.address?.stateId) {
        getCities(userDetails?.address?.stateId);
      }

      if (userDetails?.profilePic !== "") {
        setImage(`${userDetails?.backendAssetUrl}${userDetails?.profilePic}`);
      }
    }
  }, [getCountries, userDetails]);
  const handleSubmit = (values, { resetForm }) => {
    updatePromoterDetails({ ...values }, resetForm, onAction);
  };

  const onAction = () => {
    setImage(null);
    setShowEditProfile(false);
  };

  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };
    // goback code
    const navigate = useNavigate(); // Use useNavigate hoo
    const handleGoBack = () => {
      navigate(-1); // Navigate back one step
    };
    // goback code
  return (
    <>
      <div className="myprofile">
        <div className="container">
          <section className="top_row">
            <div className="left">
              <Link className="back_div">
                <span className="material-symbols-outlined" onClick={handleGoBack}>arrow_back</span>
                <h6 className="r14">My Profile</h6>
              </Link>
            </div>
          </section>
          <div className="vg22"></div>
          <section
            className="vendor_personal_info relative"
            style={{
              paddingRight: "70px",
            }}
          >
              
            <div className="left">
              <div className="v_img">
              {userDetails?.profilePic ? (
                  <img
                    src={`${userDetails?.backendAssetUrl}/${userDetails?.profilePic}`}
                    alt={userDetails?.name}
                  />
                  )
                :(
                  <img
                  src="/assets/img/user_profile_dummy.png"
                  alt="profile_pic"
                />
                )
                }
              </div>
            </div>
           
            <div className="right">
              <h4 className="m18">
                {userDetails?.name || ""}{" "}
                <span className="r12">({userDetails?.profession || ""})</span>
              </h4>
              <div className="vg6"></div>
              <h6 className="r14 d-flex align-items-center">
                <span className="d-flex align-items-center">
                  <img
                    src="../assets/img/whatsapp.png"
                    alt=""
                    className="img14"
                  />
                  {userDetails?.phoneNumber || ""} &nbsp; &nbsp;
                </span>
              </h6>
              <div className="vg3"></div>

              <h6 className="r14 d-flex align-items-center">
                <span class="material-symbols-outlined icon14">
                  location_on
                </span>{" "}
                {userDetails?.address?.cityName || ""},{" "}
                {userDetails?.address?.stateName || ""} (
                {userDetails?.address?.countryName || ""})
              </h6>
              <div className="vg6"></div>
              <h6 className="r14">{userDetails?.address?.fullAddress || ""}</h6>
            </div>
            <div className="edit_btn" onClick={() => setShowEditProfile(true)}>
              <span class="material-symbols-outlined">edit</span>
              Edit
            </div>
          </section>
          <div className="sect_divider2"></div>
          <section className="payment_method">
            <h6 className="r14">Payment Method</h6>
            <div className="vg12"></div>
            <h4 className="m14">
              {userDetails?.recievePaymentDetails?.paymentMethod || ""}&nbsp;|&nbsp;
              {userDetails?.recievePaymentDetails?.upiIdOrNumber || ""}
            </h4>
          </section>
          <div className="sect_divider2"></div>
          <section className="payment_method">
            <h6 className="r14">Received Payment Condition</h6>
            <div className="vg12"></div>
            <h4 className="m14">
              {userDetails?.recievePaymentDetails?.numberOfDays || ""} Days
            </h4>
          </section>
        </div>
      </div>

      {showEditProfile && (
        <EditProfileInfo
          show={showEditProfile}
          onHide={() => setShowEditProfile(false)}
          handleSubmit={handleSubmit}
          info={userDetails}
          countries={countries}
          states={states}
          cities={cities}
          submitting={submitting}
          handleCountryChange={handleCountryChange}
          handleStateChange={handleStateChange}
          image={image}
          setImage={setImage}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    updatePromoterDetails: (payload, resetForm, onAction) =>
      dispatch(updatePromoterDetails(payload, resetForm, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoterProfile);
