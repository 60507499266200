import moment from "moment";

export const getDigitFormat = (val) =>
  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const dateToFormat = (date, format = "DD MMM YYYY") =>
  moment(date).format(format);

export const getPageNo = (totalItems, itemsPerPage) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(
      <option key={i} value={i * 50}>
        {i * 50} Records
      </option>
    );
  }
  return pageNumbers;
};

export const isDateExpired = (targetDate) => {
  const momentTargetDate = moment(targetDate);
  const currentDate = moment();
  const isExpired = momentTargetDate.isBefore(currentDate);
  return isExpired;
};
