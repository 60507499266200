import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import navbar

import {
  getCities,
  getCountries,
  getStates,
} from "../store/actions/commonActions";
import { getPromoterList } from "../store/actions/userActions";
import { connect } from "react-redux";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import SearchFilter from "../shared/SearchFilter";
import { Paginate } from "../shared/Pagination/Paginate";
import { dateToFormat, getPageNo } from "../common/helpers/_helper";
import { DATE_FILTER_OPTIONS } from "../common/constants";

const Promoter = ({
  loading,
  lists,
  totalDocuments,
  getPromoterList,
  currentPage,
  lastPage,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    limit: 50,
    sortType: -1,
    countryId: "",
    stateId: "",
    cityId: "",
    category: "",
    search: "",
    filter: "",
  });
  useEffect(() => {
    getPromoterList({ pageNumber: 1, limit: 50 });
    getCountries();
  }, [getPromoterList, getCountries]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if (name === "countryId") {
      getStates(value);
    } else if (name === "stateId") {
      getCities(value);
    }
  };
  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // if (search || search !== null) {
      getPromoterList({
        ...filters,
        pageNumber: 1,
        limit: Number(filters.limit),
      });
      // }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filters, getPromoterList]);

  const handlePageClick = ({ selected: page }) => {
    getPromoterList({
      ...filters,
      limit: Number(filters.limit),
      pageNumber: page + 1,
    });
  };
  return (
    <div className="promoter set_filters">
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link className="back_div">
              {/* <span className="material-symbols-outlined">arrow_back</span> */}
              <h6 className="r14">Promoter</h6>
            </Link>
          </div>
        </section>
        <div className="vg12"></div>
        <section className="diff_filters">
          <div className="row row_gap">
            <div className="col-lg-10">
              <div className="left">
                <div className="filters_group">
                  <SearchFilter
                    setSearch={handleSearch}
                    search={filters.search}
                  />
                  <div className="df_single ">
                    <div className="form_field">
                      <select
                        name="countryId"
                        id="countryId"
                        onChange={handleChange}
                      >
                        <option value="">Select state</option>
                        {countries.map((country) => (
                          <option
                            value={country.countryId}
                            key={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single ">
                    <div className="form_field">
                      <select
                        name="stateId"
                        id="stateId"
                        onChange={handleChange}
                      >
                        <option value="">Select state</option>
                        {states.map((state) => (
                          <option value={state.stateId} key={state.stateId}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="df_single ">
                    <div className="form_field">
                      <select name="cityId" id="cityId" onChange={handleChange}>
                        <option value="">Select city</option>
                        {cities.map((city) => (
                          <option value={city.cityId} key={city.cityId}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="df_single right_db">
                  <div className="form_field">
                  <select name="filter" id="filter" onChange={handleChange}>
                    {DATE_FILTER_OPTIONS.map((f, i) => (
                      <option key={`date-${i}`} value={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-flex justify-content-end right_dn">
              <div className="right">
                <div className="form_field">
                  <select name="filter" id="filter" onChange={handleChange}>
                    {DATE_FILTER_OPTIONS.map((f, i) => (
                      <option key={`date-${i}`} value={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="vg12"></div>
        <div className="promoter_table table-responsive">
          <table class="col-md-12 table-bordered table-striped table-condensed cf">
            <thead class="cf">
              <tr>
                <th>S. N.</th>
                <th>Promoter Name s</th>
                <th class="numeric">Mobile</th>
                <th class="numeric">No. of Refer</th>
                <th class="numeric">Suc. Rate</th>
                <th class="numeric blue">Earned</th>
                <th>Address</th>
                <th>Reg. Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {lists.length > 0 ? (
                lists.map((list, i) => (
                  <tr key={list._id}>
                    <td data-title="S.N.">{i + 1}.</td>
                    <td data-title="Promoter Name"> {list?.name || ""}</td>
                    <td data-title="Mobile" class="numeric">
                      {list?.phoneNumber || ""}
                    </td>
                    <td data-title="No. of Refer" class="numeric">
                      {list?.referredUsers?.length || "0"}
                    </td>
                    <td data-title="Suc. Rate" class="numeric">
                      {list?.successRate || "0%"}
                    </td>
                    <td data-title="Sale" className="blue medium">
                      {list?.totalEarning || "0"}
                    </td>
                    <td data-title="Address">
                      {list?.address?.cityName || ""}{" "}
                      <p className="td_short_info">
                        {list?.address?.stateName || ""}{" "}
                      </p>
                    </td>{" "}
                    <td data-title="Category">
                      {" "}
                      {dateToFormat(list?.createdAt || "")}
                    </td>
                    <td data-title="Action">
                      <Link
                        to={`/admin/promoter/${list._id}`}
                        className="grad_text gt_border"
                      >
                        view
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="vg12"></div>
        {lists && lists.length > 0 && (
          <section className="table_pagination record_view">
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-between p-4">
                  <Paginate
                    totalCounts={totalDocuments}
                    perPage={filters.limit}
                    currentPage={currentPage}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div className="form_field">
                  <select name="limit" id="limit" onChange={handleChange}>
                    {getPageNo(totalDocuments, 50)}
                  </select>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  promoter: {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
  },
  common,
}) => {
  return {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
    ...common,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPromoterList: (payload) => dispatch(getPromoterList(payload)),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promoter);
