import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

// import navbar 
import PromoterNavbar from './PromoterNavbar'
import CopyRight from './CopyRight';

// react tabs
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const PromoterFaq = () => {
  const [key, setKey] = useState("home");

  return (
    <div className='promoter_faq'>
    <div className="container">
      <div className="my_tab fit_width">

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab
            eventKey="home"
            title={
              <div className="tab_title">
                <h5 className="m16 lh22">हिंदी में पढ़ें</h5>
              </div>
            }
          >
            <div className="categories_in_accordion curve_style accordion_big">
              <Accordion defaultActiveKey="0" className="theme_accordion style_2">
                <Accordion.Item eventKey="0" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">सक्सेस रेट क्या है ?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      एक प्रमोटर रहते हुए, जब आपके लिंक का या आपके QR Code का इस्तेमाल करके कोई यूजर वेबसाइट बनता है, तब वहां 2 ऑप्शंस होते हैं, एक होता है प्लान खरीदने का और दूसरा होता है 24 घंटे फ्री ट्रायल का। अगर यूजर फ्री ट्रायल का ऑप्शन सेलेक्ट करता है और अकाउंट बना कर वेबसाइट बना लेता है और 24 घंटे गुज़र जाने के बाद भी प्लान नहीं खरीदता है, तो उसका पेमेंट आपकी अर्निंग में नहीं जुड़ता है। यही कारण होता है कि आपका Success Rate कम हो जाता है। उदाहरण लिए आपके थ्रू 100 लोगों ने वेबसाइट बनाई और 20 लोग फ्री ट्रायल पर ही रहे, उन्होंने बाद में भी प्लान खरीदा ही नहीं, तो आपका Success Rate, 80% कहलाएगा।
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">मेरी कमाई कम दिख रही है, जबकि मेरे द्वारा वेबसाइट्स बनाने वाले यूजर्स ज़्यादा हैं</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                    Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">रिमेनिंग अमाउंट क्या है ?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                    Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">मैं BusinessBaab की टीम से कैसे संपर्क कर सकता हूँ ?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                    Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">BusinessBaab पर वेबसाइट बनाने में, मैं यूजर्स की मदद कैसे कर सकता हूँ ?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                    Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Tab>
          <Tab
            eventKey="profile"
            title={
              <div className="tab_title">
                <h5 className="m16 lh22">Read in English</h5>
              </div>
            }
          >
            <div className="categories_in_accordion curve_style accordion_big">
              <Accordion defaultActiveKey="0" className="theme_accordion style_2">
                <Accordion.Item eventKey="0" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">What is the success rate?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      As a promoter, when a user creates a website using your link or QR Code, there are two options available: either to purchase a plan or to opt for a 24-hour free trial. If the user selects the free trial option, creates an account, and builds a website but does not purchase a plan even after 24 hours, then their payment is not included in your earnings. This is why your success rate decreases. For example, if out of 100 people who created websites through you, 20 opted for the free trial and didn't purchase a plan later, then your success rate would be called 80%.
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">My earnings are low, even though I have more users creating websites through me.</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">What is the remaining amount?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">How can I contact the BusinessBaab team?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="relative">
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">How can I assist users in creating websites on BusinessBaab?</h5>
                    </div>
                    <div className="right">
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      Coming Soon
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </Tab>
        </Tabs>
      </div>







      <div className="vg22"></div>
      <h6 className="r14 text-center">
        Can't find your business? <br /> Email Us at <Link className="grad_text">
          inquiry@businessbaab.com</Link>
        <div className="vg12"></div>
        <Link to="/promoter/contactus" className="grad_text gt_border">
          Or Contact US
        </Link>

      </h6>
      <div className="dvg40"></div>
      <div className="dvg40"></div>
    </div>
  </div>

  )
}

export default PromoterFaq
