import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import TextError from "../../shared/TextError";
import { PAYMENT_PLATFORMS } from "../../common/constants";
import ActivityIndigator from "../../shared/ActivityIndigator";
import { promoterSchema } from "../../common/validations/formValidation";
import ASSETS from "../../common/images";

const EditProfileInfo = ({
  show,
  onHide,
  handleSubmit,
  info,
  countries,
  states,
  cities,
  submitting,
  handleCountryChange,
  handleStateChange,
  image,
  setImage,
}) => {
  const imageRef = useRef(null);
  const onFileChange = (event, setFieldValue) => {
    const image = event.target.files[0];
    setFieldValue("profilePic", image);
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(image);
  };
  return (
    <Modal show={show} onHide={onHide} className="my_modal" backdrop={"static"}>
      <Modal.Header closeButton>
        <Modal.Title className="m18"> Promoter Form</Modal.Title>
        <span
          class="material-symbols-outlined modal_close red"
          onClick={onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        <section
          className="promoter_form"
          style={{
            textAlign: "left",
          }}
        >
          <Formik
            initialValues={{
              name: info?.name || "",
              profession: info?.profession || "personal",
              phoneNumber: info?.phoneNumber || "",
              countryId: info?.address?.countryId || "",
              stateId: info?.address?.stateId || "",
              cityId: info?.address?.cityId || "",
              fullAddress: info?.address?.fullAddress || "",
              numberOfDays: (
                info?.recievePaymentDetails?.numberOfDays || "15"
              ).toString(),
              upiIdOrNumber: info?.recievePaymentDetails?.upiIdOrNumber || "",
              paymentMethod: info?.recievePaymentDetails?.paymentMethod || "",
              termsAndConditions: false,
              profilePic: "",
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={promoterSchema}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  maxWidth: "520px",
                  width: "100%",
                  margin: "auto",
                  padding: "0px 15px",
                }}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form_field">
                      <label htmlFor="cp_name">Name</label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                      />{" "}
                      <ErrorMessage name="name" component={TextError} />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form_field">
                      <label htmlFor="cp_wa_number">Whatsapp Number*</label>
                      <Field
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter whatsapp"
                      />
                      <ErrorMessage name="phoneNumber" component={TextError} />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form_field">
                      <label htmlFor="cp_eid">Receiver payment method*</label>
                      <Field as="select" name="paymentMethod" id="type">
                        <option value="">Select</option>
                        {PAYMENT_PLATFORMS.map((p, p_key) => (
                          <option value={p.value} key={p_key}>
                            {p.label}
                          </option>
                        ))}
                      </Field>{" "}
                      <ErrorMessage
                        name="paymentMethod"
                        component={TextError}
                      />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form_field mt-420">
                      <label htmlFor="cp_subj">UPI ID / Number*</label>
                      <Field
                        type="text"
                        name="upiIdOrNumber"
                        id="upiIdOrNumber"
                        placeholder="Enter number"
                      />{" "}
                      <ErrorMessage
                        name="upiIdOrNumber"
                        component={TextError}
                      />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form_field">
                      <label htmlFor="businesscountry">Country</label>
                      <Field
                        as="select"
                        name="countryId"
                        id="countryId"
                        onChange={(e) =>
                          handleCountryChange(e.target.value, setFieldValue)
                        }
                        value={values.countryId}
                      >
                        {" "}
                        <option value="">Select</option>
                        {(countries ?? []).map((country, i) => (
                          <option
                            key={`country_${i}`}
                            value={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="countryId" component={TextError} />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form_field">
                      <label htmlFor="businessstate">State*</label>
                      <Field
                        as="select"
                        name="stateId"
                        id="stateId"
                        onChange={(e) =>
                          handleStateChange(e.target.value, setFieldValue)
                        }
                        value={values.stateId}
                      >
                        {" "}
                        <option value="">Select</option>
                        {(states ?? []).map((state, i) => (
                          <option key={`state_${i}`} value={state.stateId}>
                            {state.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="stateId" component={TextError} />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form_field">
                      <label htmlFor="businesscity">City*</label>
                      <Field as="select" name="cityId" id="cityId">
                        <option value="">Select</option>
                        {(cities ?? []).map((city, i) => (
                          <option key={`city_${i}`} value={city.cityId}>
                            {city.name}
                          </option>
                        ))}
                      </Field>{" "}
                      <ErrorMessage name="cityId" component={TextError} />
                    </div>
                  </div>
                  <div className="vg12"></div>
                  <div className="col-12">
                    <div className="form_field">
                      <label htmlFor="cp_message" className="field_optional">
                        Full Address (optional)
                      </label>
                      <Field
                        as="textarea"
                        name="fullAddress"
                        id="fullAddress"
                        placeholder="Type here"
                      />{" "}
                      <ErrorMessage name="fullAddress" component={TextError} />
                    </div>
                    <div className="ff_gap"></div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Select your profession</label>
                    <div className="inline_radio_group with_bg">
                      <div className="radio_field">
                        <label>
                          <Field
                            type="radio"
                            name="profession"
                            value="personal"
                          />
                          Personal
                        </label>
                      </div>
                      <div className="radio_field">
                        <label>
                          <Field
                            type="radio"
                            name="profession"
                            value="businessman"
                          />
                          Businessman
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="vg12"></div>
                  <div className="col-12">
                    <label htmlFor="">Receive payment condition</label>
                    <div className="inline_radio_group with_bg">
                      <div className="radio_field">
                        <label>
                          <Field type="radio" name="numberOfDays" value="15" />
                          15 Days
                        </label>
                      </div>
                      <div className="radio_field">
                        <label>
                          <Field type="radio" name="numberOfDays" value="7" />7
                          Days
                        </label>
                      </div>
                      {/* <div className="radio_field">
                        <label>
                          <Field type="radio" name="numberOfDays" value="1" />
                          Daily Basis
                        </label>
                      </div> */}
                    </div>
                  </div>
                  <div className="vg12"></div>
                  <div className="col-12">
                    <div
                      className="upload_photo"
                      onClick={() => imageRef.current.click()}
                    >
                      <input
                        type="file"
                        hidden
                        ref={imageRef}
                        onChange={(e) => onFileChange(e, setFieldValue)}
                      />
                      <div className="img_container" id="upload">
                        <img
                          src="/assets/img/upload_img_promoter.svg"
                          alt="upload_icon"
                        />
                      </div>
                      <label htmlFor="upload">Upload Your Photo*</label>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="vg22"></div>
                  <button
                    type="submit"
                    className="modal_btn short_btn"
                    disabled={submitting}
                  >
                    {submitting ? <ActivityIndigator /> : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default EditProfileInfo;
