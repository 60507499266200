import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
import { getPromoterDetails } from "./userActions";

export const getPaymentLists = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_PAYMENT_LIST",
    });
    const { data } = await axios.post(APIs.GET_PAYMENT_LISTS, { ...payload });

    if (data.status) {
      dispatch({
        type: "SET_PAYMENT_LIST",
        payload: data.data || [],
        extra: {
          totalDocuments: data?.totalDocuments || 0,
          backendAssetUrl: data?.backendAssetUrl || "",
          currentPage: data?.currentPage || 0,
          lastPage: data?.lastPage || 0,
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_PAYMENT_FAILURE",
      err,
    });
    showThrowError(err);
  }
};

export const transferAmount =
  (payload, onAction = undefined) =>
  async (dispatch) => {
    try {
      let fd = new FormData();
      for (let key in payload) {
        fd.append(key, payload[key]);
      }

      const { data } = await axios({
        method: "post",
        url: APIs.TRANSFER_AMOUNT,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (data.status) {
        showSuccess(data.msg);
        dispatch(getPromoterDetails(payload.promoter));
        if (onAction !== undefined) onAction();
      } else {
        showError(data.msg);
      }
    } catch (err) {
      showThrowError(err);
    }
  };
