import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import SearchFilter from "../shared/SearchFilter";
import { getUsersList } from "../store/actions/userActions";
import { getCategoryList } from "../store/actions/categoryAction";
import {
  getCities,
  getCountries,
  getStates,
} from "../store/actions/commonActions";
import { connect } from "react-redux";
import { Paginate } from "../shared/Pagination/Paginate";
import { getPageNo } from "../common/helpers/_helper";
import {
  DATE_FILTER_OPTIONS,
  PLAN_TYPE_FILTER_OPTIONS,
  STATUS_TYPE_FILTER_OPTIONS,
} from "../common/constants";

const PromoterVendor = ({
  loading,
  lists,
  totalDocuments,
  categories,
  getUsersList,
  getCategoryList,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  currentPage,
  lastPage,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    limit: 50,
    sortType: 1,
    search: "",
    countryId: "",
    stateId: "",
    cityId: "",
    planType: "",
    status: "all", //paid or remaining<
    filter: "", //lifetime,1 month ,3 months,6 months,1 year
    startDate: "", //on custom
    endDate: "",
  });
  useEffect(() => {
    getUsersList({ pageNumber: 1, limit: 50 });
    getCategoryList();
    getCountries();
  }, [getUsersList, getCategoryList, getCountries]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if (name === "countryId") {
      getStates(value);
    } else if (name === "stateId") {
      getCities(value);
    }
  };
  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // if (search || search !== null) {
      getUsersList({ ...filters, pageNumber: 1, limit: Number(filters.limit) });
      // }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filters, getUsersList]);

  const handlePageClick = ({ selected: page }) => {
    getUsersList({
      ...filters,
      limit: Number(filters.limit),
      pageNumber: page + 1,
    });
  };
  return (
    <div className="promoter_vendor set_filters">
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link to={"/promoter/dashboard"} className="back_div">
              <span className="material-symbols-outlined">arrow_back</span>
              <h6 className="r14">Vendors</h6>
            </Link>
          </div>
        </section>
        <div className="vg12"></div>
        <section className="diff_filters">
          <div className="row row_gap">
            <div className="col-lg-10">
              <div className="left">
                <div className="filters_group">
                  <SearchFilter
                    setSearch={handleSearch}
                    search={filters.search}
                  />
                  <div className="df_single ">
                    <div className="form_field">
                      <select
                        name="countryId"
                        id="countryId"
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option
                            value={country.countryId}
                            key={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single ">
                    <div className="form_field">
                      <select
                        name="stateId"
                        id="stateId"
                        onChange={handleChange}
                      >
                        <option value="">Select state</option>
                        {states.map((state) => (
                          <option value={state.stateId} key={state.stateId}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="df_single ">
                    <div className="form_field">
                      <select name="cityId" id="cityId" onChange={handleChange}>
                        <option value="">Select city</option>
                        {cities.map((city) => (
                          <option value={city.cityId} key={city.cityId}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single">
                    <div className="form_field">
                      <select
                        name="planType"
                        id="planType"
                        onChange={handleChange}
                      >
                        {PLAN_TYPE_FILTER_OPTIONS.map((f, i) => (
                          <option key={`plan-type-${i}`} value={f}>
                            {f}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single">
                    <div className="form_field">
                      <select name="status" id="status" onChange={handleChange}>
                        {STATUS_TYPE_FILTER_OPTIONS.map((f, i) => (
                          <option key={`status-${i}`} value={f}>
                            {f}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="df_single right_db">
                    <div className="form_field">
                      <select name="filter" id="filter" onChange={handleChange}>
                        {DATE_FILTER_OPTIONS.map((f, i) => (
                          <option key={`date-${i}`} value={f}>
                            {f}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-flex justify-content-end right_dn">
              <div className="right">
                <div className="form_field">
                  <select name="filter" id="filter" onChange={handleChange}>
                    {DATE_FILTER_OPTIONS.map((f, i) => (
                      <option key={`date-${i}`} value={f}>
                        {f}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="vg12"></div>
        <div className="table-responsive">
          <table class="col-md-12 table-bordered table-striped table-condensed cf">
            <thead class="cf">
              <tr>
                <th>S. N.</th>
                <th>Name</th>
                <th>Business</th>
                <th class="numeric">Mobile</th>
                <th>Address</th>
                <th>Plan</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {lists.length > 0 ? (
                lists.map((list, i) => (
                  <tr key={list._id}>
                    <td data-title="S.N.">{i + 1}.</td>
                    <td data-title="Promoter Name">
                      {" "}
                      {list?.personalInfo?.name || "Unknown"}
                    </td>
                    <td>
                    {list?.businessInfo?.title || ""}
                    </td>
                    <td data-title="Mobile" class="numeric">
                      {list?.phoneNumber || ""}
                    </td>

                    <td data-title="Address">
                      {list?.businessInfo?.address?.cityName || "-"}{" "}
                      <p className="td_short_info">
                        {" "}
                        {list?.businessInfo?.address?.stateName || "-"}{" "}
                      </p>
                    </td>
                    {/* <td data-title="Plan" class="status plan notpaid">
                      <span>unpaid</span>
                    </td> */}

                    <td
                      data-title="Plan"
                      class={`status plan ${list?.subscription?.planType === "yearly" ||
                          list?.subscription?.planType === "monthly"
                          ? "mplan"
                          : "unpaid"
                        }`}
                    >
                      {list?.subscription?.planType === "yearly" ||
                        list?.subscription?.planType === "monthly" ? (
                        <span>{list?.subscription?.planType || ""} Plan</span>
                      ) : (
                        <span>Unpaid</span>
                      )}
                    </td>
                    <td
                      data-title="Payment Status"
                      class={`ps  ${list?.isFirstPaymentDone ? "green" : "grey"
                        }`}
                    >
                      {list?.isFirstPaymentDone
                        ? "Payment Received"
                        : "Remaining"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="vg12"></div>
        {lists && lists.length > 0 && (
          <section className="table_pagination record_view">
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-between p-4">
                  <Paginate
                    totalCounts={totalDocuments}
                    perPage={filters.limit}
                    currentPage={currentPage}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div className="form_field">
                  <select name="limit" id="limit" onChange={handleChange}>
                    {getPageNo(totalDocuments, 50)}
                  </select>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user: {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
  },
  category: { lists: categories },
  common,
}) => {
  return {
    loading,
    lists,
    totalDocuments,
    currentPage,
    lastPage,
    backendAssetUrl,
    categories,
    ...common,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (payload) => dispatch(getUsersList(payload)),
    getCategoryList: () => dispatch(getCategoryList()),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoterVendor);
