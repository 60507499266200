import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Switch from "react-switch"; // npm install react-switch
import { connect } from "react-redux";
import {
  changeContactStatus,
  getContactQueryLists,
  updateLocalStatus,
} from "../store/actions/contactActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { dateToFormat } from "../common/helpers/_helper";
const ContactQueriesSingle = ({
  getContactQueryLists,
  loading,
  contactQueries,
  updateLocalStatus,
}) => {
  let { type, id } = useParams();
  const [checked, setChecked] = useState(true);
  useEffect(() => {
    getContactQueryLists({ id, type });
  }, [type, id, getContactQueryLists]);
  const handleChange = (_id, checked) => {
    changeContactStatus({
      closed: checked,
      id: _id,
    }).then(() => updateLocalStatus({ closed: checked, id: _id }));
  };

  return (
    <div className="promoter contact_queries">
      {" "}
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link to={`/admin/contact-queries`} className="back_div">
              <span className="material-symbols-outlined">arrow_back</span>
              {contactQueries.length > 0 && (
                <h6 className="m16">{contactQueries[0].name || ""}</h6>
              )}
            </Link>
          </div>
        </section>
        <div className="vg22"></div>
        <div className="categories_in_accordion curve_style accordion_big">
          {contactQueries.length > 0 ? (
            <Accordion defaultActiveKey="0" className="theme_accordion style_2">
              {contactQueries.map((data, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">
                        Query {index + 1} - {dateToFormat(data.createdAt)}
                      </h5>
                    </div>
                    <div className="right">
                      <div
                        className={`theme_switch my_switch ${
                          data?.closed ? "on" : "off"
                        }`}
                      >
                        <div className="switch">
                          <Switch
                            onChange={(checked) =>
                              handleChange(data._id, checked)
                            }
                            checked={data?.closed}
                          />
                        </div>
                      </div>{" "}
                      <span class="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span class="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="r14">
                      {data.message || ""}
                      <div className="vg8"></div>
                      <Link
                        to={`https://wa.me/${data.phoneNumber}?text=hello`}
                        className="reply_whatsapp"
                      >
                        <img src="/assets/img/whatsapp_white.png" alt="" />
                        <span className="r14">Reply on WhatsApp</span>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <div>No Product Added yet</div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contact: { loading, contactQueries } }) => {
  return {
    loading,
    contactQueries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getContactQueryLists: (payload) => dispatch(getContactQueryLists(payload)),
    updateLocalStatus: (payload) => dispatch(updateLocalStatus(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactQueriesSingle);
