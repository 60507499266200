import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getContactLists } from "../store/actions/contactActions";
import {
  getCities,
  getCountries,
  getStates,
} from "../store/actions/commonActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import SearchFilter from "../shared/SearchFilter";
import { DATE_FILTER_OPTIONS } from "../common/constants";
import { Paginate } from "../shared/Pagination/Paginate";
import { getPageNo } from "../common/helpers/_helper";

const ContactQueries = ({
  loading,
  lists,
  totalDocuments,
  getContactLists,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  currentPage,
  lastPage,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    limit: 50,
    sortType: 1,
    search: "",
    countryId: "",
    stateId: "",
    cityId: "",
    type: "user",
    filter: "", //lifetime,1 month ,3 months,6 months,1 year
    startDate: "", //on custom
    endDate: "",
  });
  useEffect(() => {
    getCountries();
  }, [getCountries]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // if (search || search !== null) {
      getContactLists({
        ...filters,
        pageNumber: 1,
        limit: Number(filters.limit),
      });
      // }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filters, getContactLists]);

  const handleTab = (value) => {
    setFilters({
      ...filters,
      type: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if (name === "countryId") {
      getStates(value);
    } else if (name === "stateId") {
      getCities(value);
    }
  };
  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };
  const handlePageClick = ({ selected: page }) => {
    getContactLists({
      ...filters,
      limit: Number(filters.limit),
      pageNumber: page + 1,
    });
  };

  return (
    <div className="promoter contact_queries set_filters">
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link className="back_div">
              {/* <span className="material-symbols-outlined">arrow_back</span> */}
              <h6 className="r14">Contact Queries</h6>
            </Link>
          </div>
        </section>
        <div className="vg22"></div>
        <div className="my_tab fit_width">
          <ul className="mb-3 nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link ${filters.type === "user" && "active"}`}
                onClick={() => handleTab("user")}
              >
                <div className="tab_title">
                  <h5 className="m16 lh22">Vendors</h5>
                </div>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link ${
                  filters.type === "promoter" && "active"
                }`}
                onClick={() => handleTab("promoter")}
              >
                <div className="tab_title">
                  <h5 className="m16 lh22">Promoters</h5>
                </div>
              </button>
            </li>{" "}
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link ${filters.type === "owner" && "active"}`}
                onClick={() => handleTab("owner")}
              >
                <div className="tab_title">
                  <h5 className="m16 lh22">Website(Query)</h5>
                </div>
              </button>
            </li>
          </ul>
          <div className="vg12"></div>
          <section className="diff_filters">
            <div className="row row_gap">
              <div className="col-lg-10">
                <div className="left">
                  <div className="filters_group">
                    <SearchFilter
                      setSearch={handleSearch}
                      search={filters.search}
                    />
                    <div className="df_single ">
                      <div className="form_field">
                        <select
                          name="countryId"
                          id="countryId"
                          onChange={handleChange}
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option
                              value={country.countryId}
                              key={country.countryId}
                            >
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="df_single ">
                      <div className="form_field">
                        <select
                          name="stateId"
                          id="stateId"
                          onChange={handleChange}
                        >
                          <option value="">Select state</option>
                          {states.map((state) => (
                            <option value={state.stateId} key={state.stateId}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="df_single ">
                      <div className="form_field">
                        <select
                          name="cityId"
                          id="cityId"
                          onChange={handleChange}
                        >
                          <option value="">Select city</option>
                          {cities.map((city) => (
                            <option value={city.cityId} key={city.cityId}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="df_single right_db">
                      <div className="form_field">
                        <select
                          name="filter"
                          id="filter"
                          onChange={handleChange}
                        >
                          {DATE_FILTER_OPTIONS.map((f, i) => (
                            <option key={`date-${i}`} value={f}>
                              {f}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 d-flex justify-content-end right_dn">
                <div className="right">
                  <div className="form_field">
                    <select name="filter" id="filter" onChange={handleChange}>
                      {DATE_FILTER_OPTIONS.map((f, i) => (
                        <option key={`date-${i}`} value={f}>
                          {f}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="vg12"></div>
          <div className="promoter_table table-responsive">
            <table class="col-md-12 table-bordered table-striped table-condensed cf">
              <thead class="cf">
                <tr>
                  <th>S. N.</th>
                  <th>Name</th>
                  {filters.type === "user" && <th>Business Name</th>}
                  {filters.type === "owner" && <th>Email</th>}
                  <th class="numeric">Mobile</th>
                  <th>Address</th>
                  {filters.type !== "owner" && <th class="numeric">No. Q.</th>}
                  {filters.type === "owner" && <th>Subject</th>}
                  {filters.type === "owner" && <th>Message</th>}
                  {filters.type !== "owner" && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {lists.length > 0 ? (
                  lists.map((list, i) => (
                    <tr key={list._id}>
                      <td data-title="S.N.">{i + 1}.</td>
                      <td data-title="Promoter Name">
                        {" "}
                        <span> {list?.user?.personalInfo?.name || ""}</span>
                        <span> {list?.promoter?.name || ""}</span>
                        <span> {list?.name || ""}</span>
                      </td>
                      {filters.type === "user" && (
                        <td data-title="Business Name">
                          <span> {list?.user?.businessInfo?.title || ""}</span>
                        </td>
                      )}{" "}
                      {filters.type === "owner" && (
                        <td data-title="Business Name">
                          <span> {list?.email || ""}</span>
                        </td>
                      )}
                      <td data-title="Mobile" class="numeric">
                        {list?.user?.phoneNumber || ""}
                        <span> {list?.promoter?.phoneNumber || ""}</span>
                        <span> {list?.phoneNumber || ""}</span>
                      </td>
                      <td data-title="Address">
                        <div>
                          {" "}
                          {list?.user?.businessInfo?.address?.cityName || ""}
                          <p className="td_short_info">
                            {" "}
                            {list?.user?.businessInfo?.address?.stateName || ""}
                          </p>
                        </div>
                        <div>
                          {" "}
                          {list?.promoter?.address?.cityName || ""}
                          <p className="td_short_info">
                            {" "}
                            {list?.promoter?.address?.stateName || ""}
                          </p>
                        </div>{" "}
                        <div>
                          {" "}
                          {list?.cityName || ""}
                          <p className="td_short_info">
                            {" "}
                            {list?.stateName || ""}, {list?.countryName || ""}
                          </p>
                        </div>
                      </td>
                      {filters.type !== "owner" && (
                        <td data-title="Mobile" class="numeric">
                          {list?.count || ""}
                        </td>
                      )}
                      {filters.type === "owner" && (
                        <td> {list?.subject || ""}</td>
                      )}
                      {filters.type === "owner" && (
                        <td> {list?.message || ""}</td>
                      )}
                      {filters.type !== "owner" && (
                        <td data-title="Action">
                          <Link
                            to={`/admin/contact-queries/${filters?.type}/${list?._id}`}
                            className="grad_text gt_border"
                          >
                            view
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="vg12"></div>
          {lists && lists.length > 0 && (
            <section className="table_pagination record_view">
              <div className="row">
                <div className="col-6">
                  <div className="d-flex justify-content-between p-4">
                    <Paginate
                      totalCounts={totalDocuments}
                      perPage={filters.limit}
                      currentPage={currentPage}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <div className="form_field">
                    <select name="limit" id="limit" onChange={handleChange}>
                      {getPageNo(totalDocuments, 50)}
                    </select>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  contact: { loading, lists, totalDocuments, currentPage, lastPage },
  common,
}) => {
  return {
    loading,
    lists,
    totalDocuments,
    currentPage,
    lastPage,
    ...common,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getContactLists: (payload) => dispatch(getContactLists(payload)),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactQueries);
