import React from "react";
import SuperAdminNavbar from "./SuperAdminNavbar";
import { Routes, Route } from "react-router-dom";
import SuperAdminDashboard from "./SuperAdminDashboard";
import Vendor from "./Vendor";
import VendorSingle from "./VendorSingle";
import Promoter from "./Promoter";
import PromoterSingle from "./PromoterSingle";
import ContactQueries from "./ContactQueries";
import ContactQueriesSingle from "./ContactQueriesSingle";

const SuperAdminLayout = () => {
  return (
    <div className="superadmin_page_spacing">
      <SuperAdminNavbar />
      <Routes>
        <Route path="/" element={<SuperAdminDashboard />}></Route>{" "}
        <Route path="/dashboard" element={<SuperAdminDashboard />}></Route>{" "}
        <Route path="/vendor" element={<Vendor />}></Route>{" "}
        <Route path="/vendor/:id" element={<VendorSingle />}></Route>{" "}
        <Route path="/promoter" element={<Promoter />}></Route>{" "}
        <Route path="/contact-queries" element={<ContactQueries />}></Route>{" "}
        <Route
          path="/contact-queries/:type/:id"
          element={<ContactQueriesSingle />}
        ></Route>{" "}
        <Route
          path="/promoter/:promoterId"
          element={<PromoterSingle />}
        ></Route>
      </Routes>
    </div>
  );
};

export default SuperAdminLayout;
