import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "../authentication/Login";
import Otp from "../authentication/Otp";
import ProtectedRoute from "./ProtectedRoute";
import SuperAdminLayout from "../superadmin/SuperAdminLayout";
import PromoterLayout from "../promoter/PromoterLayout";

export const Router = () => {
  return (
    <BrowserRouter>
    {/* //  <BrowserRouter basename="/admin"> */}
      {/* "homepage": "https://businessbaab.com/admin/", */}
      {/* routes for super admin start */}
      <Routes>
        <Route path="/admin/login" element={<Login type="admin" />}></Route>{" "}
        {/* <Route path="/admin/login" element={<Login type="admin" />}></Route>{" "} */}
        <Route path="/admin/verify-otp" element={<Otp type="admin" />}></Route>
        <Route
          path="/"
          element={<Login type="promoter" />}
        ></Route>{" "}
        <Route
          path="/promoter/verify-otp"
          element={<Otp type="promoter" />}
        ></Route>
        {/* superadmin */}
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <SuperAdminLayout />
            </ProtectedRoute>
          }
        />
        {/* promoter */}
        <Route
          path="/promoter/*"
          element={
            <ProtectedRoute>
              <PromoterLayout />
            </ProtectedRoute>
          }
        />{" "}
      </Routes>
    </BrowserRouter>
  );
};
