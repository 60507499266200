import { APIs } from "../common/constants";
import { showError, showThrowError } from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: "RESET_COUNTRY",
    });
    const { data } = await axios.get(APIs.GET_ALL_COUNTRY);

    if (data.status) {
      dispatch({
        type: "SET_COUNTRY",
        payload: data.data || [],
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const getStates = (countryId) => async (dispatch) => {
  try {
    dispatch({
      type: "RESET_STATE",
    });
    const { data } = await axios.get(
      `${APIs.GET_ALL_STATE_BY_COUNTRY}?countryId=${countryId}`
    );

    if (data.status) {
      dispatch({
        type: "SET_STATE",
        payload: data.data || [],
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const getCities = (stateId) => async (dispatch) => {
  try {
    dispatch({
      type: "RESET_CITY",
    });

    const { data } = await axios.get(
      `${APIs.GET_ALL_CITY_BY_STATE}?stateId=${stateId}`
    );
    if (data.status) {
      dispatch({
        type: "SET_CITY",
        payload: data.data || [],
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
