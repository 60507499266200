const initialState = {
  error: "",
  loading: false,
  lists: [],
  totalDocuments: 0,
  currentPage: 0,
  lastPage: 0,
  contactQueries: [],
};
const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONTACT_LISTS":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_CONTACT_LISTS":
      return {
        ...state,
        loading: false,
        lists: [...action.payload],
        ...action.extra,
      };
    case "SAVE_CONTACT_ERROR":
      return { ...state, loading: false, error: action.err.message };
    case "SET_CONTACT_QUERIES":
      return {
        ...state,
        loading: false,
        contactQueries: [...action.payload],
      };
    case "RESET_CONTACT_QUERIES":
      return { ...state, loading: false, contactQueries: [] };
    case "UPDATE_CONTACT_STATUS":
      let updatedArray = state.contactQueries.map((obj) => {
        if (obj._id === action.payload.id) {
          return { ...obj, closed: action.payload.closed };
        }
        return obj;
      });
      return {
        ...state,
        loading: false,

        contactQueries: updatedArray,
      };
    default:
      return state;
  }
};
export default contactReducer;
