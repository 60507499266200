import React from "react";

const SearchFilter = ({
  search,
  setSearch,
  placeholder,
  showLabel = false,
}) => {
  return (
    <div className="df_single search_bar">
      {showLabel && <label htmlFor="payment">Search</label>}
      <div className="form_field with_icon">
        <input
          type="search"
          name="search"
          placeholder={placeholder ? placeholder : "Search"}
          value={search ? search : ""}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="field_icon">
          <span class="material-symbols-outlined">search</span>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
