import { APIs } from "../common/constants";
import { showThrowError } from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const getDashboardItems = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DASHBOARD_ITEM" });

    const { data } = await axios.post(`${APIs.GET_ANALYTICS_COUNTS}`, {
      ...payload,
    });

    if (data.status) {
      dispatch({
        type: "SET_DASHBOARD_ITEM",
        payload: {
          counts: {
            //superadmin
            totalUsers: data.data?.totalUsers || 0,
            totalYearlyUsers: data.data?.totalYearlyUsers || 0,
            totalMonthlyUsers: data.data?.totalMonthlyUsers || 0,
            totalUnpaidUsers: data.data?.totalUnpaidUsers || 0,
            totalReferredUsers: data.data?.totalReferredUsers || 0,
            totalGrantUsers: data.data?.totalGrantUsers || 0,
            totalEarning: data.data?.totalEarning || 0,
            totalSiteEarning: data.data?.totalSiteEarning || 0,
            totalYealyEarning: data.data?.totalYealyEarning || 0,
            totalMonthlyEarning: data.data?.totalMonthlyEarning || 0,
            // promoter
            totalYearlyPlanUsers: data.data?.totalYearlyPlanUsers || 0,
            totalMonthlyPlanUsers: data.data?.totalMonthlyPlanUsers || 0,
            totalPaidUsers: data.data?.totalPaidUsers || 0,
            sucessRate: data.data?.sucessRate || "0%",
            totalPaidAmount: data.data?.totalPaidAmount || 0,
            totalRemainingAmount: data.data?.totalRemainingAmount || 0,
          },
          topLocations: data.data?.topLocations || [],
          topCategories: data.data?.topCategories || [],
          topLocationsEarnings: data.data?.topLocationsEarnings || [],
        },
      });
    } else {
      dispatch({
        type: "SET_DASHBOARD_ITEM",
        payload: {
          counts: {
            //superadmin
            totalUsers: 0,
            totalYearlyUsers: 0,
            totalMonthlyUsers: 0,
            totalUnpaidUsers: 0,
            totalReferredUsers: 0,
            totalGrantUsers: 0,
            totalEarning: 0,
            totalSiteEarning: 0,
            totalYealyEarning: 0,
            totalMonthlyEarning: 0,
            //promoter
            totalYearlyPlanUsers: 0,
            totalMonthlyPlanUsers: 0,
            totalPaidUsers: 0,
            sucessRate: "0%",
            totalPaidAmount: 0,
            totalRemainingAmount: 0,
          },
          topLocations: [],
          topCategories: [],
          topLocationsEarnings: [],
        },
      });
    }
  } catch (err) {
    dispatch({
      type: "FETCH_DASHBOARD_ITEM_ERROR",
      payload: err.message,
    });
    showThrowError(err.message);
  }
};
