import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPaymentLists } from "../store/actions/paymentActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { dateToFormat, getPageNo } from "../common/helpers/_helper";
import { Paginate } from "../shared/Pagination/Paginate";

const PaymentHistory = ({
  getPaymentLists,
  loading,
  lists,
  totalDocuments,
  backendAssetUrl,
  currentPage,
  lastPage,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    limit: 10,
    sortType: 1,
    search: "",
  });
  useEffect(() => {
    getPaymentLists({ ...filters });
  }, [getPaymentLists, filters]);
  const handleDownload = (list) => {
    const link = document.createElement("a");
    link.href = `${backendAssetUrl}${list.attachment}`;
    link.download = "screenshot";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handlePageClick = ({ selected: page }) => {
    getPaymentLists({
      ...filters,
      limit: Number(filters.limit),
      pageNumber: page + 1,
    });
  };
  return (
    <div className="superadmin vendoranalytics">
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link to={"/promoter/dashboard"} className="back_div">
              <span className="material-symbols-outlined">arrow_back</span>
              <h6 className="r14">Payment History</h6>
            </Link>
          </div>
        </section>
        <div className="vg12"></div>
        <div className="table-responsive">
          <table className="col-md-12 table-bordered table-striped table-condensed cf">
            <thead className="cf">
              <tr>
                <th>S. N.</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {lists.length > 0 &&
                lists.map((list, i) => (
                  <tr key={list._id}>
                    <td data-title="S.N.">{i + 1}.</td>
                    <td>
                      {" "}
                      <span className="r14">{dateToFormat(list.date)}</span>
                    </td>
                    <td>
                      <span className="semibold">{list.amount || "0"}</span>
                    </td>
                    <td>
                      {" "}
                      <div
                        className="d-flex align-item-center"
                        onClick={() => handleDownload(list)}
                      >
                        <span
                          className="material-symbols-outlined grad_text"
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          download
                        </span>
                        <span className="grad_text">Screenshot</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="vg12"></div>
        <section className="table_pagination record_view">
          <div className="row">
            <div className="col-6">
              {lists && lists.length > 0 && (
                <div className="d-flex justify-content-between p-4">
                  {/* <div>
                    <h5>Total {totalDocuments} records</h5>
                  </div> */}
                  <Paginate
                    totalCounts={totalDocuments}
                    perPage={filters.limit}
                    currentPage={currentPage}
                    handlePageClick={handlePageClick}
                  />
                </div>
              )}
            </div>
            <div className="col-6 d-flex justify-content-end">
              <div className="form_field">
                <select name="limit" id="limit" onChange={handleChange}>
                  {getPageNo(totalDocuments, 10)}
                </select>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  payment: {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
  },
}) => {
  return {
    loading,
    lists,
    totalDocuments,
    backendAssetUrl,
    currentPage,
    lastPage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentLists: (payload) => dispatch(getPaymentLists(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
