import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ActivityIndigator from "../shared/ActivityIndigator";
import { useDispatch } from "react-redux";
import { transferAmount } from "../store/actions/paymentActions";

const TransferAmount = ({ show, onHide, details }) => {
  const [amount, setAmount] = useState(0);
  const [action, setAction] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const attachmentRef = useRef();
  const dispatch = useDispatch();
  const onFileChange = (event) => {
    const image = event.target.files[0];
    setAttachment(image);
  };
  const actionHandler = () => {
    console.log("click");
    setAction(true);
    dispatch(
      transferAmount(
        {
          promoter: details?._id,
          attachment,
          paymentMethod: "online",
          amount,
        },
        onAction
      )
    );
  };
  const onAction = () => {
    setAmount(0);
    setAction(false);
    setAttachment(null);
    onHide();
  };

  const getBtnDisabled = () => {
    if (action) {
      return true;
    }
    if (amount > 0 && attachment !== null) {
      return false;
    }
    return true;
  };
  return (
    <Modal show={show} onHide={onHide} className="my_modal" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Payment Transfer</Modal.Title>
        <span
          class="material-symbols-outlined modal_close red"
          onClick={onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        <div className="payment_history">
          {" "}
          <div className="left">
            <h6 className="r14">
              Remaining :{" "}
              <span className="m18">{details?.totalRemainingAmount || 0}</span>
            </h6>{" "}
            <div>
              <label>Enter Amount:</label>
              <input
                type="number"
                value={amount}
                min={0}
                max={details?.totalRemainingAmount}
                onChange={(e) => setAmount(e.target.value)}
                className="form-control"
              />
            </div>
            <div>
              <input
                type="file"
                hidden
                ref={attachmentRef}
                onChange={(e) => onFileChange(e)}
              />
              <span>
                {" "}
                {attachment !== null && <span>{attachment.name}</span>}
              </span>
              <br />
              <span
                className="grad_text gt_border"
                onClick={() => attachmentRef.current.click()}
              >
                {attachment !== null ? "Upload again" : "Upload  pay slip"}
              </span>
            </div>
          </div>{" "}
          {console.log(getBtnDisabled())}
          <button
            className="modal_btn"
            onClick={actionHandler}
            disabled={getBtnDisabled()}
          >
            {action ? <ActivityIndigator /> : <span>Pay</span>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransferAmount;
