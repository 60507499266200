import Swal from "sweetalert2";

const showAlert = (title, message, type, duration = 2000, extra = {}) => {
  const options = {
    title: title,
    text: message,
    icon: type,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "OK",
  };
  // Add timer option if duration is provided
  if (duration) {
    options.timer = duration;
    options.timerProgressBar = true;
    options.showConfirmButton = false;
  }

  return Swal.fire(options);
};

const success = (msg) => {
  showAlert("Success", msg, "success");
};

const error = (msg) => {
  showAlert("Error", msg, "error");
};
/*
{
    position: "top-end",
    title: "Are you sure?",
    text: "This process is irreversible.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, go ahead.",
    cancelButtonText: "No, let me think",
  }
  */
const alertConfirmation = () => {
  showAlert("Are you sure?", "This process is irreversible.", "warning", 5000, {
    position: "top-end",
    timerProgressBar: false,
    showCancelButton: true,
    confirmButtonText: "Yes, go ahead.",
    cancelButtonText: "No, let me think",
  });
};

export { showAlert, success, error, alertConfirmation };

export default { showAlert, success, error, alertConfirmation };
