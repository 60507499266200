import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import Switch from "react-switch"; // npm install react-switch
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import {
  changeUserStatus,
  deleteVender,
  getUsersDetails,
} from "../store/actions/userActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { getGender } from "../common/utils/utils";
import ASSETS from "../common/images";
import { dateToFormat } from "../common/helpers/_helper";
import { PAYMENT_PLATFORMS } from "../common/constants";
import AlertPopup from "../shared/Model/AlertPopup";

const VendorSingle = ({
  getUsersDetails,
  loading,
  details,
  service,
  backendAssetUrl,
  resetDetail,
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [popupAction, setPopupAction] = useState(false);
  const [saSwitch, setSASwitch] = useState(true);
  const [showSAAlert, setShowSAAlert] = useState(false);
  const [ngSwitch, setNGSwitch] = useState(false);
  const [showNGAlert, setShowNGAlert] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    getUsersDetails(id);
    return () => resetDetail();
  }, [id, getUsersDetails, resetDetail]);
  useEffect(() => {
    if (details) {
      setSASwitch(details?.status);
      setNGSwitch(details?.isGrantUser);
    }
  }, [details]);

  const getLabel = (type) => {
    let payment = PAYMENT_PLATFORMS.find((p) => p.value === type);
    return payment !== undefined ? payment?.label ?? "" : "";
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleNGSwitch = () => {
    setShowNGAlert(!showSAAlert);
  };

  const toggleSwitch = () => {
    setShowSAAlert(!showSAAlert);
  };
  const handleChangeSwitch = () => {
    setPopupAction(true);
    changeUserStatus({
      userId: id,
      type: "status",
      status: !saSwitch,
    })
      .then(() => {
        setShowSAAlert(false);
        setSASwitch(!saSwitch);
      })
      .finally(() => setPopupAction(false));
  };
  const handleChangeNGSwitch = () => {
    setPopupAction(true);
    changeUserStatus({
      userId: id,
      type: "isGrantUser",
      isGrantUser: !ngSwitch,
    })
      .then(() => {
        setShowNGAlert(false);
        setNGSwitch(!ngSwitch);
      })
      .finally(() => setPopupAction(false));
  };

  const handleDelete = () => {
    setPopupAction(true);
    deleteVender(id)
      .then(() => {
        setShowDeleteAlert(false);
        navigate("/admin/vendor");
      })
      .finally(() => setPopupAction(false));
  };
  // console.log("detail", details.personalInfo);
  console.log("detail", details);
  return (
    <div className="vendor_single">
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="top_row">
          <div className="left">
            <Link className="back_div">
              <span
                className="material-symbols-outlined"
                onClick={handleGoBack}
              >
                arrow_back
              </span>
              <h6 className="r14">Vendor Information</h6>
            </Link>
          </div>
          <div className="right">
            <div className="diff_action">
              <div className="action_single">
                <div
                  className={`my_switch switch_with_inline_label ${
                    ngSwitch ? "on" : "off"
                  }`}
                >
                  <div className="switch">
                    <h6 className={`r12 ${ngSwitch ? "" : "blue"}`}>Normal</h6>
                    <Switch checked={ngSwitch} onChange={toggleNGSwitch} />
                    <h6 className={`r12 ${ngSwitch ? "blue" : ""}`}>Grant</h6>
                  </div>
                </div>
              </div>
              <div className="action_single">
                <div
                  className={`my_switch switch_with_inline_label ${
                    saSwitch ? "on" : "off"
                  }`}
                >
                  <div className="switch">
                    <h6 className={`r12 ${saSwitch ? "" : "blue"}`}>Stop</h6>
                    <Switch checked={saSwitch} onChange={toggleSwitch} />
                    <h6 className={`r12 ${saSwitch ? "blue" : ""}`}>Active</h6>
                  </div>
                </div>
              </div>
              {/* <div className="action_single">
                <div className="form_field">
                  <select name="" id="">
                    <option value="">Paid</option>
                    <option value="">Unpaid</option>
                  </select>
                </div>
              </div> */}
             <span class="material-symbols-outlined action_single red pointer"
                 onClick={() => setShowDeleteAlert(true)}>delete_forever</span>
            </div>
          </div>
        </section>
        <div className="vg22"></div>
        <section className="vendor_personal_info">
          {/* <div className="left">
            <div className="v_img">
              {details?.businessInfo?.logo && (
                <img
                  src={`${backendAssetUrl}/${details?.businessInfo?.logo}`}
                  alt={details?.businessInfo?.title}
                />
              )}
            </div>
            <div className="vg8"></div>
           
          </div> */}
          <div className="right">
            <h4 className="m18">
              {getGender(details?.personalInfo?.gender)}{" "}
              {details?.personalInfo?.name || ""}
            </h4>
            <div className="vg6"></div>
            <h6 className="r14 d-flex align-items-center">
              <span class="material-symbols-outlined icon14">location_on</span>{" "}
              {details?.personalInfo?.address?.cityName || ""},{" "}
              {details?.personalInfo?.address?.stateName || ""} (
              {details?.personalInfo?.address?.countryName || ""})
            </h6>
            <div className="vg3"></div>
            <h6 className="r14 d-flex align-items-center">
              <span className="d-flex align-items-center">
                <img src={ASSETS.callIcon} alt="" className="img14" />
                {details?.personalInfo?.phoneNumber || ""}, &nbsp; &nbsp;
              </span>
              <span className="d-flex align-items-center">
                <img src={ASSETS.whatsappIcon} alt="" className="img14" />
                {details?.personalInfo?.whatsapNumber || ""}, &nbsp; &nbsp;
              </span>
              <span className="d-flex align-items-center">
                <img src={ASSETS.emailIcon} alt="" className="img14" />
                {details?.businessInfo?.email || ""}, &nbsp; &nbsp;
              </span>
            </h6>
            <div className="vg6"></div>
            <h6 className="r14">
              {details?.personalInfo?.address?.fullAddress},
              {details?.personalInfo?.address?.cityName},
              {details?.personalInfo?.address?.stateName},
              {details?.personalInfo?.address?.countryName}
            </h6>
            <div className="vg8"></div>
            <div className="vpi_bottom">
              {details?.subscription && (
                <div>
                  <h5 className="m12 lh22 blue text-center" style={{
                    textTransform:"capitalize"
                  }}>
                    {" "}
                    {details?.subscription?.planType || ""} plan{" "}
                  </h5>
                  <h6 className="r12 text-center blue">
                    {details?.subscription?.paymentAt &&
                      details?.subscription?.expiresAt && (
                        <span>
                          {dateToFormat(details?.subscription?.paymentAt) || ""}
                          {" to "}
                          {dateToFormat(details?.subscription?.expiresAt) || ""}
                        </span>
                      )}
                  </h6>
                </div>
              )}
              <div className="vendor_uploded_links">
                <div className="vul_inner">
                  <div className="vu_social vc_single">
                    {(details?.social || []).map((link, i) => (
                      <Link
                        className="vus_single"
                        to={link.link}
                        key={`social-${i}`}
                      >
                        <img src={ASSETS[link.type]} alt={link.type} />
                      </Link>
                    ))}
                  </div>
                  <div className="vu_payment vc_single">
                    {(details?.paymentInfo || []).map((link, i) => (
                      <Link
                        className="vup_single"
                        key={`payment-${i}`}
                        to={link.link}
                      >
                        <img src={ASSETS[link.type]} alt={link.type} />
                      </Link>
                    ))}
                  </div>
                </div>
                <Link
                  to={`https://businessbaab.com/${details?.publicIdentifier}`}
                  target="_blank"
                  className="theme_btn short_btn view_btn"
                >
                  Go to Website
                </Link>
              </div>
            </div>
            <div className="vg8"></div>
            {details?.referredBy && (
              <div>
                <h5 className="m12 lh22 blue text-center">
                  {" "}
                  {details?.promoterInfo?.name || ""} (
                  {details?.promoterInfo?.phoneNumber || ""})
                </h5>
              </div>
            )}
          </div>
        </section>
        <div className="sect_divider2"></div>
        <section className="company_info">
          <section className="">
            <div className="left">
              <div className="v_img vb_img">
                {details?.businessInfo?.logo && (
                  <img
                    src={`${backendAssetUrl}/${details?.businessInfo?.logo}`}
                    alt={details?.businessInfo?.title}
                  />
                )}
              </div>
            </div>
            <div className="right">
              <h4 className="m18">{details?.businessInfo?.title || ""}</h4>
              <div className="vg6"></div>
              <h6 className="r14 d-flex align-items-center" >
                {details?.businessInfo?.designation || ""}
              </h6>
              <div className="vg3"></div>
              <h6 className="r14 d-flex align-items-center">
                <span class="material-symbols-outlined icon14">
                  location_on
                </span>{" "}
                {details?.personalInfo?.address?.fullAddress},
              </h6>
              <h6
                className="r14 d-flex align-items-center g-col"
                style={{
                  gap: "5px",                 
                }}
              >
                {details?.personalInfo?.address?.cityName},
                {details?.personalInfo?.address?.stateName},
                {details?.personalInfo?.address?.countryName}
                <Link
                  to={details?.businessInfo?.address?.googleLink || ""}
                  className="theme_btn short_btn view_btn"
                >
                  Google Map
                </Link>
              </h6>
            </div>
          </section>
          <div className="vg12"></div>
          <h6 className="r14">
          {details?.businessInfo?.bio || ""}
          </h6>
          <div className="vg12"></div>
          <div className="v_slider_img">
            {(details?.businessInfo?.images || []).map((image, i) => (
              <div className="vsi_single" key={`image-${i}`}>
                <img src={`${backendAssetUrl}/${image}`} alt={`slide-${i}`} />
              </div>
            ))}
          </div>
        </section>
        <div className="sect_divider2"></div>
        <section className="vendor_products">
          <div className="d-flex justify-content-between" style={{
            flexWrap:"wrap"
          }}>
            <h5 className="m18">
              Products{" "}
              <span className="r14">
                (Total {service?.totalDocuments || 0} Products)
              </span>
            </h5>
            <h5 className="r14">
              Total Sale :{" "}
              <span className="m18 blue">{details?.totalSales || 0}</span>
            </h5>
          </div>
          {service?.data?.length > 0 &&
            service?.data?.map((s) => (
              <div className="vp_single" key={s._id}>
                {s.thumbnail ? (
                  <div className="left">
                    <img src={`${backendAssetUrl}/${s.thumbnail}`} alt="" />
                  </div>
                ) : (
                  <div className="left">
                  <img src='/assets/img/product_dummy.svg' alt="" />
                </div>
                )
              
              }

                <div className="right">
                  <h5 className="m18">{s.title || ""}</h5>
                  <h6 className="r14">
                    ₹{s.price || 0} ({s.quantity || 0} Available)
                  </h6>
                  <h6 className="r14">{s.description || ""}</h6>
                </div>
                <div className="total_sale">Sale :{s.soldQuantity || 0}</div>
              </div>
            ))}
        </section>
      </div>

      {showDeleteAlert && (
        <AlertPopup
          onHide={() => setShowDeleteAlert(false)}
          show={showDeleteAlert}
          actionHandler={handleDelete}
          message={"Are you sure you want to delete this vendor?"}
          action={popupAction}
          buttonText={"Delete"}
        />
      )}
      {showSAAlert && (
        <AlertPopup
          onHide={() => setShowSAAlert(false)}
          show={showSAAlert}
          actionHandler={handleChangeSwitch}
          message={`Are you sure you want to ${
            saSwitch ? "stop" : "active"
          } this vendor?`}
          action={popupAction}
          buttonText={saSwitch ? "Stop Vendor" : "Active Vendor"}
        />
      )}
      {showNGAlert && (
        <AlertPopup
          onHide={() => setShowNGAlert(false)}
          show={showNGAlert}
          actionHandler={handleChangeNGSwitch}
          message={`Make sure that you are giving ${
            ngSwitch ? "normal" : "lifetime grant"
          } to this vendor`}
          action={popupAction}
          buttonText={ngSwitch ? "Make Normal" : "Make Grant"}
        />
      )}
    </div>
  );
};
const mapStateToProps = ({
  user: { loading, details, service, backendAssetUrl },
}) => {
  return {
    loading,
    details,
    service,
    backendAssetUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersDetails: (_id) => dispatch(getUsersDetails(_id)),
    resetDetail: () => {
      dispatch({ type: "RESET_USERS_DETAILS" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorSingle);
