import React from "react";
import { Modal } from "react-bootstrap";

export const LogoutConfirmation = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="my_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Alert</Modal.Title>
        <span
          class="material-symbols-outlined modal_close red"
          onClick={props.onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        <h6 className="r16">Are you sure you want to logout?</h6>
      </Modal.Body>
      <div className="vg22"></div>
      <div className="modal_btn pointer" onClick={props.actionHandler}>
        Logout
      </div>
    </Modal>
  );
};
