import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data } = await axios.get(APIs.GET_PROMTER_DETAILS);
    dispatch({
      type: "SHOW_ACCOUNT_LOADER",
    });
    if (data.status) {
      dispatch({
        type: "APPEND_PROFILE_DATA",
        payload: {
          ...data.data,
          backendAssetUrl: data?.backendAssetUrl,
        },
      });

      if (data?.data?.categoryName) {
        localStorage.setItem("categoryName", data?.data?.categoryName);
      }
    } else if (!data.status) {
      showError(data.msg);
    }
    dispatch({
      type: "HIDE_ACCOUNT_LOADER",
    });
  } catch (err) {
    dispatch({
      type: "HIDE_ACCOUNT_LOADER",
    });

    showThrowError(err);
  }
};

export const getUsersList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_USERS_LIST",
    });
    const { data } = await axios.post(APIs.GET_USER_LISTS, { ...payload });

    if (data.status) {
      dispatch({
        type: "SET_USERS_LIST",
        payload: data.data || [],
        extra: {
          totalDocuments: data?.totalDocuments || 0,
          backendAssetUrl: data?.backendAssetUrl || "",
          currentPage: data?.currentPage || 0,
          lastPage: data?.lastPage || 0,
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_USERS_FAILURE",
      err,
    });
    showThrowError(err);
  }
};

export const getUsersDetails = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_USERS_LIST",
    });
    const { data } = await axios.get(`${APIs.GET_SINGLE_USER_DETAILS}/${_id}`);

    if (data.status) {
      dispatch({
        type: "SET_USERS_DETAILS",
        payload: data.data || [],
        extra: {
          service: data?.service || 0,
          backendAssetUrl: data?.backendAssetUrl || "",
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_USERS_FAILURE",
      err,
    });
    showThrowError(err);
  }
};

export const getPromoterList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_PROMOTERS_LIST",
    });
    const { data } = await axios.post(APIs.GET_PROMOTER_LISTS, { ...payload });

    if (data.status) {
      dispatch({
        type: "SET_PROMOTERS_LIST",
        payload: data.data || [],
        extra: {
          totalDocuments: data?.totalDocuments || 0,
          backendAssetUrl: data?.backendAssetUrl || "",
          currentPage: data?.currentPage || 0,
          lastPage: data?.lastPage || 0,
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_PROMOTERS_FAILURE",
      err,
    });
    showThrowError(err);
  }
};

export const getPromoterDetails = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_PROMOTERS_LIST",
    });
    const { data } = await axios.get(
      `${APIs.GET_SINGLE_PROMOTER_DETAILS}/${_id}`
    );

    if (data.status) {
      dispatch({
        type: "SET_PROMOTERS_DETAILS",
        payload: data.data || [],
        extra: {
          service: data?.service || 0,
          backendAssetUrl: data?.backendAssetUrl || "",
        },
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_PROMOTERS_FAILURE",
      err,
    });
    showThrowError(err);
  }
};
export const deletePromoter = async (id) => {
  try {
    const { data } = await axios.delete(`${APIs.DELETE_PROMOTER}/${id}`);
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const changePromoterStatus = async (payload) => {
  try {
    const { data } = await axios.post(`${APIs.CHANGE_PROMOTER_STATUS}`, {
      ...payload,
    });
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const deleteVender = async (id) => {
  try {
    const { data } = await axios.delete(`${APIs.DELETE_USER}/${id}`);
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const changeUserStatus = async (payload) => {
  try {
    const { data } = await axios.post(`${APIs.CHANGE_USER_STATUS}`, {
      ...payload,
    });
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};
