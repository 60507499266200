import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./Promoter.css";
import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";
import { getDashboardItems } from "../store/actions/homeActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { connect } from "react-redux";
import CardPreviewModel from "./models/CardPreviewModel";
import { showSuccess } from "../store/common/helpers/responseHelper";

const PromoterDashboard = ({
  loading,
  counts,
  getDashboardItems,
  userDetails,
}) => {
  useEffect(() => {
    getDashboardItems({});
  }, [getDashboardItems]);
  let URL = `https://businessbaab.com/login?referralCode=${userDetails?.referralCode}`;

  const [showPromoterQR, setShowPromoterQR] = useState(false);
  const onShare = async () => {
    const data = {
      title: "Business Baap",
      url: URL,
    };
    // console.log(data);
    if (navigator.share) {
      navigator
        .share(data)
        .then(() => {
          console.log("Successful share! 🎉");
        })
        .catch((err) => console.error(err));
    } else {
      console.log("no share");
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(URL).then(
      () => {
        showSuccess("Link Copied");
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const handleDownload = () => {
    const downloadCard = document.getElementById("dowanlod_visting_card");

    // Use html2canvas to convert the div content to an image
    html2canvas(downloadCard, { width: 340 }).then((canvas) => {
      // Convert canvas to data URL
      const dataURL = canvas.toDataURL("image/png");

      // Create a temporary link element
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = "visting-card.png";

      // Append the link to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Remove the link from the document
      document.body.removeChild(a);
    });
  };
  return (
    <>
      {loading ? <ActivityLoader /> : null}
      <div className="promoter_dashboard">
        <div className="container">
          <section className="site_info_cards">
            <div className="site_info_cards_inner">
              <div className="sic_single blue">
                <h4>{counts?.totalUsers || 0}</h4>
                <h6>Total Users</h6>
              </div>
              <div className="sic_single">
                <h4>{counts?.totalPaidUsers || 0}</h4>
                <h6>
                  {counts?.totalYearlyUsers || 0} Y |{" "}
                  {counts?.totalMonthlyUsers || 0} M
                </h6>
              </div>
              <div className="sic_single">
                <h4>{counts?.totalUnpaidUsers || 0}</h4>
                <h6>Unpaid Users</h6>
              </div>
              <div className="sic_single">
                <h4>{counts?.sucessRate || "0%"}</h4>
                <h6>Success Rate</h6>
              </div>
            </div>
          </section>
          <div className="savg30"></div>
          <section className="revenue_cards">
            <div className="revenue_card_inner">
              <div className="revenue_card_single white">
                <h4>{counts?.totalEarning || 0}</h4>
                <h5>My Total Earning</h5>
              </div>
              <div className="revenue_card_single">
                <h4>{counts?.totalYealyEarning || 0}</h4>
                <h5>{counts?.totalYearlyPlanUsers || 0} Yearly Users</h5>
              </div>
              <div className="revenue_card_single">
                <h4>{counts?.totalMonthlyEarning || 0}</h4>
                <h5>{counts?.totalMonthlyPlanUsers || 0} Monthly Users</h5>
              </div>
              <div className="revenue_card_single">
                <h4>{counts?.totalRemainingAmount || 0}</h4>
                <h5>Remaining Amount</h5>
              </div>
            </div>
          </section>
          <div className="savg30"></div>
          <Link
            to={"/promoter/payment-history"}
            className="theme_btn short_btn btn_border"
          >
            View Payment History
          </Link>
          <div className="sect_divider2"></div>
          <section className="sect_visiting_card">
            <div className="visiting_card" id="dowanlod_visting_card">
              <div className="vc_top">
                <div className="left">{userDetails?.phoneNumber || ""}</div>
                <div className="right text-end">
                  <span>{userDetails?.name || ""}</span>
                  <h6>Promoter</h6>
                </div>
              </div>
              <div className="vc_body">
                <div className="text-center">
                  <img
                    src="/assets/img/logo_businessbaab2.svg"
                    alt="businessbaab"
                    style={{
                      height: "44px",
                      width: "auto",
                    }}
                  />
                </div>
                <div className="qr_div">
                  <span className="m10 text-end">
                    स्केन करें और 10 मिनट में अपनी खुद की वेबसाइट बनाएँ मात्र
                    ₹83 माह में
                  </span>
                  <div className="vc_qr_code">
                    <QRCodeSVG
                      value={URL}
                      size={"100%"}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"L"}
                      includeMargin={false}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <span className="m10">
                    टेक्नोलॉजी पर हम फोकस करेंगे, आप बस अपने बिज़नेस पर ध्याद दो
                    !
                  </span>
                </div>
              </div>
              <div className="vc_footer">
                आपका बिज़नेस जो भी हो, अब उसे ऑनलाइन करने का समय आ गया है
              </div>
            </div>
            <div className="theme_btn short_btn btn_with_icon" onClick={handleDownload}>
              <span class="material-symbols-outlined btn_icon">
                download
              </span>
              Download & Print Visiting Card
            </div>
          </section>
          <div className="sect_divider2"></div>
          <section>
            <Link className="r16 black">{URL}</Link>
            <div className="vg12"></div>
            <div
              className="d-flex align-items-center"
              style={{
                gap: "12px",
                flexWrap:"wrap"
              }}
            >
              <div className="theme_btn short_btn btn_with_icon" onClick={onShare}>
                <span class="material-symbols-outlined btn_icon">
                  share
                </span>
                Share your link
              </div>
              <div className="theme_btn short_btn  btn_border btn_with_icon" onClick={onCopy}>
                <span class="material-symbols-outlined btn_icon">
                  content_copy
                </span>
                Copy Link
              </div>
            </div>
          </section>
          <div className="sect_divider2"></div>
          <section>
            <div
              className="theme_btn short_btn btn_with_icon"
              onClick={() => setShowPromoterQR(true)}
            >
                  <span class="material-symbols-outlined btn_icon">
                download
              </span>
              Download your QR Code
            </div>
          </section>
        </div>
      </div>
      <div className="dvg20"></div>
      {/* Modal for Payment history */}
      {/* <Modal
        show={showPromoterPaymentHistory}
        onHide={handleShowPromoterPaymentHistoryModal}
        className="my_modal dowanlod_qr_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">Payment History</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleClosePromoterPaymentHistoryModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <div className="payment_history">
            <div className="ph_single">
              <span className="r14">02/02/2024</span>
              <span className="semibold">2,000</span>
              <div className="d-flex align-item-center">
                <span
                  class="material-symbols-outlined grad_text"
                  style={{
                    fontSize: "22px",
                  }}
                >
                  download
                </span>
                <span className="grad_text">Screenshot</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* Modal for Payment history */}

      {showPromoterQR && (
        <CardPreviewModel
          show={showPromoterQR}
          onHide={() => setShowPromoterQR(false)}
          URL={URL}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ dashboard: { loading, counts } }) => {
  return {
    loading,
    counts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardItems: (payload) => dispatch(getDashboardItems(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoterDashboard);
