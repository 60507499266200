import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { logout } from "../store/actions/authActions";
import { LogoutConfirmation } from "../shared/Model/LogoutConfirmation";
import { useDispatch } from "react-redux";

const SuperAdminNavbar = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout(navigate));
  };
  return (
    <Navbar expand="lg" className="bg-body-tertiary superadmin_navbar">
      <Container>
        <Link className="brand_logo">
          <img src="/assets/img/logo_businessbaab2.svg" alt="" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="sa_menus">
            <Link
              to="/admin/dashboard"
              className={`sam_single ${
                location.pathname === "/" ? "sam_active" : ""
              }`}
            >
              Dashboard
            </Link>
            <Link
              to="/admin/vendor"
              className={`sam_single ${
                location.pathname === "/vendor" ||
                location.pathname === "/vendorsingle"
                  ? "sam_active"
                  : ""
              }`}
            >
              Vendor
            </Link>
            <Link
              to="/admin/promoter"
              className={`sam_single ${
                location.pathname === "/promoter" ||
                location.pathname === "/promotersingle"
                  ? "sam_active"
                  : ""
              }`}
            >
              Promoter
            </Link>
            <Link
              to="/admin/contact-queries"
              className={`sam_single ${
                location.pathname === "/contact-queries"
                  ? "sam_active"
                  : ""
              }`}
            >
            Contact Queries
            </Link>
            <Link
              onClick={() => setShowModal(true)}
              className={`sam_single ${
                location.pathname === "/logout" ? "sam_active" : ""
              }`}
            >
              Logout
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
      <LogoutConfirmation
        show={showModal}
        onHide={() => setShowModal(false)}
        actionHandler={onLogout}
      />
    </Navbar>
  );
};

export default SuperAdminNavbar;
