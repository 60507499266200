import "./App.css";
// import theme css
import "./theme.css";

import { Router } from "./routes/Router";

function App() {
  return (
    <div className="page">
      <Router />
    </div>
  );
}

export default App;
