const initialCommonState = {
  countries: [],
  states: [],
  cities: [],
};

export const commonReducer = (state = initialCommonState, action) => {
  switch (action.type) {
    case "SET_COUNTRY":
      return {
        ...state,
        countries: action.payload,
      };
    case "SET_STATE":
      return {
        ...state,
        states: action.payload,
      };
    case "SET_CITY":
      return {
        ...state,
        cities: action.payload,
      };
    case "RESET_COUNTRY":
      return {
        ...state,
        countries: [],
      };
    case "RESET_STATE":
      return {
        ...state,
        states: [],
      };
    case "RESET_CITY":
      return {
        ...state,
        cities: [],
      };

    default:
      return state;
  }
};

const initialProfileState = {
  error: "",
  loading: false,
  user: {},
  limitedService: {
    services: [],
    totalServices: 0,
    backendAssetUrl: "",
  },
  singleService: {},
  services: [],
  totalService: 0,
  backendAssetUrl: "",
  loadingMore: false,
  hasMore: true,
};
export const profileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case "FETCH_PUBLIC_USER_PROFILE":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_PUBLIC_USER_PROFILE":
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload,
        limitedService: action.extra,
      };
    case "FETCH_PUBLIC_PROFILE_ERROR":
      return { ...state, loading: false, error: action.err };
    case "SET_PUBLIC_USER_SERVICES":
      return {
        ...state,
        loading: false,
        error: null,
        services: [...action.payload],
        ...action.extra,
      };
    case "LOADING_MORE_PUBLIC_USER_SERVICES":
      return { ...state, error: null, loadingMore: true };
    case "APPEND_PUBLIC_USER_SERVICES":
      return {
        ...state,
        error: null,
        services: [...state.services, ...action.payload],
        ...action.extra,
        loadingMore: false,
      };
    case "SET_SSERVICE_DETAIL":
      return { ...state, loading: false, singleService: action.payload };
    case "RESET_SERVICE_DETAIL":
      return { ...state, loading: false, singleService: {} };
    default:
      return state;
  }
};

const initialShoppingCartState = {
  cartItemCount: 0,
  items: [],
};
export const shoppingCartReducer = (
  state = initialShoppingCartState,
  action
) => {
  switch (action.type) {
    case "SET_SHOPPING_CART_COUNT":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_SHOPPING_CART_ITEM":
      return {
        ...state,
        cartItemCount: 0,
        items: [],
      };

    default:
      return state;
  }
};
