import React, { useEffect } from "react";

// css
import "./SuperAdmin.css";

// import navbar
import SuperAdminNavbar from "./SuperAdminNavbar";
import { getDashboardItems } from "../store/actions/homeActions";
import { connect } from "react-redux";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { getCategoryList } from "../store/actions/categoryAction";

const SuperAdminDashboard = ({
  loading,
  counts,
  getDashboardItems,
  topLocations,
  topCategories,
  topLocationsEarnings,
  getCategoryList,
  categories,
}) => {
  useEffect(() => {
    getDashboardItems({});
    getCategoryList();
  }, [getDashboardItems, getCategoryList]);

  const getCategoryPercentage = (category) => {
    if (topCategories.length > 0) {
      let cat = topCategories.find((c) => c.category === category._id);
      if (cat) {
        return (
          <span>
            <span>{cat?.percentage}</span> ({cat?.count} users)
          </span>
        );
      }
    }
    return (
      <span>
        <span>0%</span> (0 users)
      </span>
    );
  };
  return (
    <div className="superadmin sa_dashboard">
      {" "}
      {loading ? <ActivityLoader /> : null}
      <div className="container">
        <section className="site_info_cards">
          <div className="site_info_cards_inner">
            <div className="sic_single blue">
              <h4>{counts?.totalUsers || 0}</h4>
              <h6>Total Users</h6>
            </div>
            <div className="sic_single">
              <h4>
                {(counts?.totalYearlyPlanUsers || 0) +
                  (counts?.totalMonthlyPlanUsers || 0)}
              </h4>
              <h6>
                {counts?.totalYearlyPlanUsers || 0} Y |{" "}
                {counts?.totalMonthlyPlanUsers || 0}
                M <br /> Direct paid users
              </h6>
            </div>
            <div className="sic_single">
              <h4>{counts?.totalUnpaidUsers || 0}</h4>
              <h6>Unpaid Users</h6>
            </div>
            <div className="sic_single">
              <h4>{counts?.totalReferredUsers || 0}</h4>
              <h6>Referential Users</h6>
            </div>
            <div className="sic_single yellow">
              <h4>{counts?.totalGrantUsers || 0}</h4>
              <h6>Grant Users</h6>
            </div>
          </div>
        </section>
        <div className="savg30"></div>
        <section className="revenue_cards">
          <div className="revenue_card_inner">
            <div className="revenue_card_single white">
              <h4>
                {(counts?.totalEarning || 0) -
                  (counts?.totalPromoterCommission || 0)}
              </h4>
              <h5>{counts?.totalEarning || 0} (Gross)</h5>
              <h6>Total Revenue</h6>
            </div>
            <div className="revenue_card_single">
              <h4>{counts?.totalSiteEarning || 0}</h4>
              <h5>
                {counts?.totalYealyEarning || 0} Y |{" "}
                {counts?.totalMonthlyEarning || 0} M
              </h5>
              <h6>Direct paid users</h6>
            </div>
            <div className="revenue_card_single">
              <h4>
                {Math.round(
                  (30 * (counts?.totalPromoterCommission || 0)) / 100
                )}
              </h4>
              <h5>{counts?.totalPromoterCommission || 0} (-30%)</h5>
              <h6>Referential Rev.</h6>
            </div>
            <div className="revenue_card_single relative">
              <h4>16,800</h4>
              <h5>10,000 Y - (99 U)</h5>
              <h6>6,800 M - (250 U)</h6>
              <div className="tag">Grant</div>
            </div>
          </div>
        </section>
        <div className="savg30"></div>
        <section className="portal_cat_card">
          <div className="portal_cat_card_inner">
            {categories.length > 0 &&
              categories.map((cat) => (
                <div className="pcc_single text-center" key={cat._id}>
                  <h6>{getCategoryPercentage(cat)}</h6>
                  <div className="pccs_inner">{cat?.name}</div>
                </div>
              ))}
          </div>
        </section>
        <div className="savg30"></div>
        <section className="top_cities">
          <h4>
            Top 10 Cities <span>(Users)</span>
          </h4>
          <div className="cities_card">
            {topLocations.length > 0 &&
              topLocations.map((location) => (
                <div className="cc_single" key={location._id}>
                  <h6 className="r10">({location?.count || 0})</h6>
                  <div className="ccs_inner r14">{location?.cityName || 0}</div>
                </div>
              ))}
          </div>
        </section>
        <div className="savg30"></div>
        <section className="top_cities">
          <h4>
            Top 10 Cities <span>(Revenue)</span>
          </h4>
          <div className="cities_card">
            {topLocationsEarnings.length > 0 &&
              topLocationsEarnings.map((location) => (
                <div className="cc_single" key={location._id}>
                  <h6 className="r10">({location?.count || 0})</h6>
                  <div className="ccs_inner r14">{location?.cityName || 0}</div>
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  dashboard: {
    loading,
    counts,
    topLocations,
    topCategories,
    topLocationsEarnings,
  },
  category: { lists: categories },
}) => {
  return {
    loading,
    counts,
    topLocations,
    topCategories,
    topLocationsEarnings,
    categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardItems: (payload) => dispatch(getDashboardItems(payload)),
    getCategoryList: () => dispatch(getCategoryList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminDashboard);
