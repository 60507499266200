const initialState = {
  error: "",
  loading: false,
  user: {},
  lists: [],
  totalDocuments: 0,
  currentPage: 1,
  lastPage: 1,
  backendAssetUrl: "",
  details: {},
  service: {
    totalDocuments: 0,
    currentPage: 0,
    lastPage: 0,
    data: [],
  },
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_PROFILE":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SAVE_BASIC_PROFILE":
      return { ...state, loading: false, error: null, user: action.payload };
    case "SAVE_PROFILE_ERROR":
      return { ...state, loading: false, error: action.err.message };
    case "APPEND_PROFILE_DATA":
      return {
        ...state,
        error: null,
        loading: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case "FETCH_USERS_LIST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_USERS_LIST":
      return {
        ...state,
        loading: false,
        lists: action.payload,
        ...action.extra,
      };
    case "SET_USERS_DETAILS":
      return {
        ...state,
        loading: false,
        details: action.payload,
        ...action.extra,
      };
    case "RESET_USERS_DETAILS":
      return {
        ...state,
        loading: false,
        details: {},
        service: {
          totalDocuments: 0,
          currentPage: 0,
          lastPage: 0,
          data: [],
        },
      };

    case "SET_USERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.err?.message,
      };

    default:
      return state;
  }
};

const dashboardState = {
  counts: {
    totalUsers: 0,
    totalYearlyUsers: 0,
    totalMonthlyUsers: 0,
    totalUnpaidUsers: 0,
    totalReferredUsers: 0,
    totalGrantUsers: 0,
    totalEarning: 0,
    totalSiteEarning: 0,
    totalYealyEarning: 0,
    totalMonthlyEarning: 0,
    sucessRate: "0%",
    totalPaidAmount: 0,
    totalRemainingAmount: 0,
    totalYearlyPlanUsers: 0,
    totalMonthlyPlanUsers: 0,
  },
  topLocations: [],
  topCategories: [],
  topLocationsEarnings: [],
  loading: false,
  error: "",
};

export const dashboardReducer = (state = dashboardState, action) => {
  switch (action.type) {
    case "FETCH_DASHBOARD_ITEM":
      return { ...state, loading: true };
    case "SET_DASHBOARD_ITEM":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "FETCH_DASHBOARD_ITEM_ERROR":
      return {
        ...state,
        counts: {
          totalVisitors: 0,
          totalDownloads: 0,
          totalLeads: 0,
          totalDoneOrders: 0,
          totalRevenue: 0,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
