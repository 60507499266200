import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { sendContactDetails } from "../store/actions/contactActions";
import ActivityIndigator from "../shared/ActivityIndigator";
import TextError from "../shared/TextError";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { contactSchema } from "../common/validations/formValidation";
import { getSession } from "../store/actions/authActions";

const PContactUs = ({ profile, submitting, sendContactDetails }) => {
  let { name, email, phoneNumber, user_id } = getSession();
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    sendContactDetails(
      {
        ...values,
        name,
        email,
        phoneNumber,
        senderType: "promoter",
        promoter: user_id,
      },
      resetForm
    );
  };
  return (
    <div className="promoter_contactus">
      <div className="container">
        <div className="pcu_inner">
          <h6 className="m16">Contact to Admin</h6>
          <div className="vg22"></div>
          <h6 className="r16">
            Have a question or need assistance? Use the form below to send a
            message to the admin. We'll get back to you as soon as possible.
          </h6>
          <Formik
            initialValues={initialValues}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="vg12"></div>
                <div className="form_field">
                  <label htmlFor="">Describe your query</label>
                  <Field
                    as="textarea"
                    name="message"
                    id="message"
                    placeholder="Describe your query"
                  />{" "}
                  <ErrorMessage name="message" component={TextError} />
                </div>
                <div className="vg22"></div>

                <button
                  type="submit"
                  className="theme_btn short_btn"
                  disabled={submitting}
                >
                  {submitting ? <ActivityIndigator /> : "Submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return { submitting: form.submitting };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendContactDetails: (payload, onAction) =>
      dispatch(sendContactDetails(payload, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PContactUs);
