import React from "react";
import { Navigate, Route } from "react-router-dom";
import { getSession } from "../store/actions/authActions";

const ProtectedRoute = ({ children }) => {
  let { Authorization } = getSession();
  return Authorization ? <>{children}</> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
