export const SOCIAL_PLATFORMS = [
  {
    icon: "facebook",
    label: "Facebook",
    value: "facebook",
  },
  {
    icon: "twitter",
    label: "Twitter",
    value: "twitter",
  },
  {
    icon: "snapchat",
    label: "Snapchat",
    value: "snapchat",
  },
  {
    icon: "linkedin",
    label: "Linked In",
    value: "linkedin",
  },
  {
    icon: "threads",
    label: "Threads",
    value: "threads",
  },
  {
    icon: "instagram",
    label: "Instagram",
    value: "instagram",
  },
  {
    icon: "telegram",
    label: "Telegram",
    value: "telegram",
  },
];

export const DESIGNATION_LISTS = ["Owner", "Manager"];
export const PAYMENT_PLATFORMS = [
  { icon: "phonepe", label: "Phonepe", value: "phonepe" },
  { icon: "gpay", label: "G-Pay", value: "gpay" },
  { icon: "paytm", label: "Paytm", value: "paytm" },
  { icon: "bhim", label: "BHIM", value: "bhim" },
  { icon: "bharatpay", label: "Bharat pay", value: "bharatpay" },
];

export const FILTER_OPTIONS = [
  {
    id: 1,
    value: "LATEST",
    label: "Latest",
  },
  {
    id: 2,
    value: "OLDEST",
    label: "Oldest",
  },
  {
    id: 3,
    value: "HIGH_TO_LOW_PRICE",
    label: "High to low price",
  },
  {
    id: 4,
    value: "LOW_TO_HIGH_PRICE",
    label: "Low to high price",
  },
];

export const DATE_FILTER_OPTIONS = [
  "lifetime",
  "1 month",
  "3 months",
  "6 months",
  "1 year",
  "last year",
  // "custom",
];

export const STATUS_TYPE_FILTER_OPTIONS = ["All", "Paid", "Remaining"];
export const PLAN_TYPE_FILTER_OPTIONS = ["All", "Yearly", "Monthly", "Unpaid"];
export const PLAN_TYPE_FILTER_OPTIONS_EXPAND = [
  "All",
  "Yearly",
  "Monthly",
  "Unpaid",
  "Grant",
  "Stopped",
  "Expired",
];
export const VENDER_TYPE_FILTER_OPTIONS = ["All", "Direct", "Referral"];

export const VIEW_ORDER_BY_OPTIONS = [
  {
    label: "Latest",
    value: "LATEST",
  },
  {
    label: "Oldest",
    value: "OLDEST",
  },
  {
    label: "High to Low",
    value: "HIGH_TO_LOW",
  },
  {
    label: "Low to High",
    value: "LOW_TO_HIGH",
  },
];
export const PAGINATION_LIMIT = 10;
