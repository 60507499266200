import React from "react";
import { Modal } from "react-bootstrap";
import ActivityIndigator from "../ActivityIndigator";

const AlertPopup = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="my_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Alert</Modal.Title>
        <span
          className="material-symbols-outlined modal_close red"
          onClick={props.onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        <h6 className="r16">{props?.message}</h6>      
      </Modal.Body>
      <div className="vg22"></div>

      <button
        className="modal_btn"
        onClick={props.actionHandler}
        disabled={props.action}
      >
        {props.action ? <ActivityIndigator /> : <span>{props.buttonText}</span>}
      </button>
    </Modal>
  );
};

export default AlertPopup;
