import React, { useEffect } from "react";
import PromoterNavbar from "./PromoterNavbar";
import { Routes, Route } from "react-router-dom";
import PromoterDashboard from "./PromoterDashboard";
import PromoterFaq from "./PromoterFaq";
import PromoterContactUs from "./PromoterContactUs";
import PromoterVendor from "./PromoterVendor";
import PromoterProfile from "./PromoterProfile";
import CopyRight from "./CopyRight";
import { getUserInfo } from "../store/actions/userActions";
import ActivityLoader from "../shared/ActivityLoader/ActivityLoader";
import { connect } from "react-redux";
import PaymentHistory from "./PaymentHistory";

const PromoterLayout = ({ getUserInfo, loader, userDetails }) => {
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <>
      {loader ? <ActivityLoader /> : null}
      <div className="promoter_page_spacing">
        <PromoterNavbar />{" "}
        <Routes>
          {" "}
          <Route path="/" element={<PromoterDashboard />}></Route>
          <Route
            path="/dashboard"
            element={<PromoterDashboard userDetails={userDetails} />}
          ></Route>
          <Route path="/faq" element={<PromoterFaq />}></Route>
          <Route path="/contactus" element={<PromoterContactUs />}></Route>
          <Route path="/vendor" element={<PromoterVendor />}></Route>
          <Route
            path="/profile"
            element={<PromoterProfile userDetails={userDetails} />}
          ></Route>
          <Route path="/payment-history" element={<PaymentHistory />}></Route>
        </Routes>
      </div>{" "}
      <CopyRight />
    </>
  );
};
const mapStateToProps = ({ form, account, user }) => {
  return {
    loader: account.loader,
    userDetails: user?.user || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoterLayout);
